import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import {
  MemberShipData,
  sectors,
  projects,
  memberShipCreate,
  changeReducerState,
  sectordata
} from "../../store/actions/MemberShipActions";
import { workShopUser } from "../../store/actions/WorkShopActions";

import _ from "lodash";
import Creatable, { components  } from "react-select";
import { useParams } from "react-router-dom"
import { Activity, addActivity } from "../../store/actions/ActivityActions";
const AddMembership = ({ leadDetail, setWsStatus, userId }) => {
  const { id } = useParams()

  const [fields, setFields] = useState({
    name: leadDetail?.full_name ?? "",
    mobile: leadDetail?.mobile ?? "",
    email: leadDetail?.email ?? "",
    plan: true,
    leadId: leadDetail?.id ?? "",
    pincode: leadDetail?.pincode ?? "",

  });
  const [project, setProject] = useState([]);
  const [sector, setSector] = useState([]);
  const [courselist, setSubCourseCategory] = useState([]);
  const [course, setCourseCategory] = useState("");
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState({});
  
  const [selectedId, setSelectedId] = useState(null); 
  const [discount, setDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState();
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const {
    memberShips: { memberShip },
    errors,
    loading,
    sectors: { subcategory },
    projects: { project_reports },
    sectorsDrop,
    createStatus,
    membershipStatus,
  } = useSelector((state) => state.memberShip);
  useEffect(() => {
    const dataa = {
      lead_id: leadDetail?.id,
      activity_type: "Membership",
      description: "Membership Sale",
    };
    if (membershipStatus) {
      dispatch(addActivity(dataa, token));
    }
  }, [membershipStatus]);
  const {
    users: { user },
  } = useSelector((state) => state.workShop);

  useEffect(() => {

    dispatch(MemberShipData(token));
    dispatch(sectors(token));
  }, [token]);

  useEffect(() => {
  console.log("sectorsDrop =>",sectorsDrop);
  }, [sectorsDrop]);
  
  const memberShipData = useMemo(() => memberShip, [memberShip]);

  const selectedMemberShip = useMemo(() => {
   

    if (fields?.selectedMemberShip) {
      let selected = memberShip.find(
       
        (v) => v.value == fields?.selectedMemberShip,
        
      );
     console.log(selected,'ss');
      return selected;
    }
  }, [fields?.selectedMemberShip]);
  const handleSwitchChange = () => {
    setFields({ ...fields, status: !fields.status });
  };
  const handleSectorChange = (val) => {
    console.log(val ,'handleSectorChange')
    console.log(selectedMemberShip?.industries ,'selectedMemberShip?.industries')
    if (!val || val.length <= parseInt(selectedMemberShip?.industries)) {
     
      setSector(val);
      if (!val) {
        setProject("");
      }
    }
  };

  const handleSectorCourseChange = (val) => {
    if (!val || val.length <= parseInt(selectedMemberShip?.industries)) {
      console.log('handleSectorCourseChange =>',val);
      setSector(val);
      if (!val) {
        setProject("");
      }
    }
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
    // console.log('handleChange =>>',[id],value);
    dispatch(sectordata(token,value));

  };
  const handleProjectChange = (value) => {
    if (!value || value.length <= parseInt(selectedMemberShip?.sub_category)) {
      setProject(value);
    }
  };

  useMemo(() => {
    if (fields.mobile) {
      if (fields.mobile?.length == 10) {
        dispatch(workShopUser(token, fields.mobile));
      }
    }
  }, [fields.mobile, fields.workShop]);
  useEffect(() => {

    setSelectedId(3250)
    if (user && modal) {
      if (!_.isEmpty(user?.plan_detail)) {
        NotificationManager.error("User already taken MemberShip", "Error");
      }
      setFields({
        ...fields,
        name: user.name,
        email: user.email,
        id: user.id,
      });
    } else if (leadDetail) {
      setFields({
        ...fields,
        name: leadDetail?.full_name ?? "",
        mobile: leadDetail?.mobile ?? "",
        email: leadDetail?.email ?? "",
        leadId: leadDetail?.id ?? "",
      });
    } else {
      setFields({
        ...fields,
        name: "",
        email: "",
        id: "",
      });
    }
  }, [user, leadDetail]);

  
  const sectorData = useMemo(() => {
   // console.log(fields?.selectedMemberShip ,'fields?.selectedMemberShipfields?.selectedMemberShip')
    if (fields?.selectedMemberShip != 6) 
    {
      return subcategory; // If no selected ID, return empty array
    } else {
  // Find the selected option in subcategory
  const selectedOption = subcategory?.find(option => option?.value === selectedId);
  console.log(selectedOption ,'selectedOption')
 if (selectedOption) {
   // Return an array with only the selected option
   return [selectedOption];
 }
    }
    // Return empty array if no matching option found
    return subcategory;
  }, [selectedId, subcategory,fields?.selectedMemberShip]);

  const courseData = useMemo(() => courselist, [courselist]);
  const subSubCategories = useMemo(() => project_reports, [project_reports]);
  useEffect(() => {
    if (!_.isNull(sector) && selectedMemberShip?.sub_category) {
      let sectorValue = _.map(sector, (s) => s.value);
      dispatch(projects(token, sectorValue));
    }
  }, [token, sector]);
  const Menu = (menuProps) => {
    const optionSelectedLength = menuProps.getValue().length || 0;
    return (
      <components.Menu {...menuProps}>
        {optionSelectedLength < parseInt(selectedMemberShip?.sub_category) ? (
          menuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {selectedMemberShip?.sub_category} Sector
          </div>
        )}
      </components.Menu>
    );
  };

  const Menu1 = menuProps => {
    const optionSelectedLength = menuProps.getValue().length || 0;
    return (
        <components.Menu {...menuProps}>
            {optionSelectedLength < parseInt(sector) ? (
                menuProps.children
            ) : (
                <div style={{ margin: 15 }}>
                    Only {sector} Course
                </div>
            )}
        </components.Menu>
    );
};
  const projectMenu = (projectMenuProps) => {
    const optionSelectedLength1 = projectMenuProps.getValue().length || 0;
    return (
      <components.Menu {...projectMenuProps}>
        {optionSelectedLength1 < parseInt(selectedMemberShip?.sub_category) ? (
          projectMenuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {parseInt(selectedMemberShip?.sub_category)} Project
          </div>
        )}
      </components.Menu>
    );
  };
  const handleDocumentChange = (e) => {
    setFields({
      ...fields,
      document: e.target.files[0],
    });
  };
  const validationForm = () => {
    console.log("validationForm =>>>>> " , fields?.bussinessName,selectedMemberShip?.sub_category);
    let formIsValid = true;
    if(fields?.selectedMemberShip != 13){
      if (!_.size(sector)) {
        formIsValid = false;
        error["sector"] = "Sector is required.";
        NotificationManager.error("Sector is required.", "Error");
      }
      if (!_.size( project)) {
        formIsValid = false;
        error["project"] = "Proposed Area of Industry Report is required.";
        NotificationManager.error("Proposed Area of Industry Report is required.", "Error");
      }
    } else if (fields?.selectedMemberShip == 13){
      if (!_.size(ttt)) {
        formIsValid = false;
        error["course"] = "Course is required.";
        NotificationManager.error("Course is required.", "Error");
      }
    }
  
    if (!_.size(fields?.selectedMemberShip)) {
      formIsValid = false;
      error["membership"] = "Membership is required.";
      NotificationManager.error("Membership is required.", "Error");
    }
    if (!_.size(fields?.email)) {
      formIsValid = false;
      error["email"] = "Email is required.";
      NotificationManager.error("Email is required.", "Error");
    }
    if (!_.size(fields?.name)) {
      formIsValid = false;
      errors["name"] = "Name is required.";
      NotificationManager.error("Name is required.", "Error");
    }
    // if (!_.size(fields?.selectedMemberShip)) {
    //   formIsValid = false;
    //   error["membership"] = "Membership is required.";
    //   NotificationManager.error("Membership is required.", "Error");
    // }
   // if (selectedMemberShip?.sub_category > 0) {
      // if (!_.size(project)) {
      //   formIsValid = false;
      //   error["project"] = "Project is required.";
      //   NotificationManager.error("Project is required.", "Error");
      // }
   // }
    setError(error);
    return formIsValid;
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validationForm()) {
  //     const data = {
  //       user_id: fields?.id,
  //       leadId: fields?.leadId ?? "",
  //       name: fields?.name,
  //       email: fields?.email,
  //       mobile: fields?.mobile,
  //       payment_id: fields?.trxId,
  //       mailing: fields?.maillingAddress,
  //       sectors: sector.map((se) => {
  //         return {
  //           sector: se.value,
  //           project: _.filter(project, (p) => p.sector === se.value),
  //         };
  //       }),
  //       membership_type: selectedMemberShip?.label,
  //       membership: fields?.selectedMemberShip,
  //       total: selectedMemberShip?.total,
  //       amount: selectedMemberShip?.total,
  //       expiry: selectedMemberShip?.expiry,
  //       sub_sub_category: selectedMemberShip?.sub_category,
  //       bussinessName: fields?.bussinessName,
  //       pincode: fields?.pincode,
  //       gst: fields?.gst,
  //       mode: fields?.mode,
  //       paymentType: fields?.paymentType,
  //       engaged: 1,
  //     };
  //     dispatch(memberShipCreate(data, token));
  //   }
  // };

  const sc = JSON.stringify(
    sector?.map((se) => {
      return {
        sector: se.value,
        project: _.filter(project, (p) => p.sector === se.value),
      };
    })
  );
  const ttt = sector?.map((se) => {
    return {
      sector: se.value,
      project: _.filter(project, (p) => p.sector === se.value),
    };
  });
  
  
  useEffect(() => {
    Axios
      .get(`/api/crm/courses-industry-list1`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSubCourseCategory(res?.data?.course);
      })
      .catch((err) => console.log(err));
  }, [token]);

  const calculateDiscountAmount = () => {
    if (selectedMemberShip) {
      const totalAmount = selectedMemberShip.total;
      const amountAfterDiscount = totalAmount - discount;
  
      setDiscountAmount(amountAfterDiscount);
    }
  };

  useEffect(() => {
     calculateDiscountAmount();
  }, [discount, selectedMemberShip]);

 
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      const formData = new FormData();

      formData.append("user_id", id ? id : fields?.id);
      formData.append("name", fields?.name);
      formData.append("email", fields?.email);
      formData.append("mobile", fields?.mobile);
      formData.append("payment_id", fields?.trxId);
      formData.append("mailing", fields?.maillingAddress);
      formData.append("sectors", JSON.stringify(ttt));
      formData.append("membership_type", selectedMemberShip?.label);
      formData.append("membership", fields?.selectedMemberShip);
      formData.append("total", discountAmount);
      formData.append("amount", discountAmount);
      formData.append("discount", discount);
      formData.append("expiry", selectedMemberShip?.expiry);
      formData.append("sub_sub_category", selectedMemberShip?.sub_category);
      formData.append("bussinessName", fields?.bussinessName == undefined ? "" : fields?.bussinessName);
      formData.append("pincode", fields?.pincode);
      formData.append("gst", fields?.gst);
      formData.append("mode", fields?.mode);
      formData.append("mode_type", fields?.mode_type);
      formData.append("paymentType", fields?.paymentType);
      formData.append("payment_date", fields?.payment_date);
      console.log(formData ,'formData')
     dispatch(memberShipCreate(formData, token));
    }
  };

  useEffect(() => {
    setFields({
      ...fields,
      name: leadDetail?.full_name ?? "",
      mobile: leadDetail?.mobile ?? "",
      email: leadDetail?.email ?? "",
      leadId: leadDetail?.id ?? "",
    });
  }, [leadDetail, modal]);

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 &&
    selectValue.length < parseInt(selectedMemberShip?.industries);
  useEffect(() => {
    if (createStatus) {
      setWsStatus(true);
      dispatch(changeReducerState());
      NotificationManager.success("Created Successfully", "Success");
    }
  }, [createStatus]);
  if (!isAuthenticated) return <Redirect to="/login" />;

 
  console.log(fields?.selectedMemberShip ,'fields?.selectedMemberShip')
  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        MemberShip
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} backdrop="static">
        <ModalHeader tag="h5" toggle={() => setModal(false)} >
          Add MemberShip
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="selectedMemberShip">MemberShip <span className="text-danger">*</span></Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="selectedMemberShip"
                    invalid={!!errors?.membership}
                    value={
                      fields?.selectedMemberShip
                        ? fields?.selectedMemberShip
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <option>select</option>
                    {_.size(memberShipData)
                      ? _.map(memberShipData, (mem) => (
                        <option value={mem?.value} key={mem?.value}>
                          {mem?.label}/{mem?.total}
                        </option>
                      ))
                      : null}
                  </Input>
                  <FormFeedback>
                    {!!errors?.selectedMemberShip
                      ? errors?.selectedMemberShip[0]
                      : ""}
                  </FormFeedback>
                  <FormFeedback>
                    {!!errors?.membership
                      ? errors?.membership[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              {/* {!_.isUndefined(selectedMemberShip) ? (
                <Col sm="6">
                  <h5>Membership Plan</h5>
                  <p>
                    <strong>For Individual/Enterprises/Startups</strong>
                  </p>
                  <CustomInput
                    type="switch"
                    id="plan"
                    name="plan"
                    label={`${selectedMemberShip?.label} ${selectedMemberShip?.total}`}
                    checked={fields.plan}
                    onChange={handleSwitchChange}
                  />
                  <p>
                    The entire fee amount is inclusive of GST calculated @ 18%.
                  </p>
                </Col>
              ) : null} */}
              <Col sm={6}>
                <FormGroup>
                  <Label for="mobile">Mobile <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.mobile}
                    id="mobile"
                    value={fields?.mobile ? fields?.mobile : ""}
                    onChange={handleChange}
                    disabled
                  />
                  <FormFeedback>
                    {!!errors.mobile ? errors.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">Name <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.name}
                    id="name"
                    value={fields.name ? fields.name : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.name ? errors.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">Email <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.email}
                    id="email"
                    value={fields.email ? fields.email : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.email ? errors.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="maillingAddress">Mailing Address</Label>
                  <Input
                    type="textarea"
                    invalid={!!errors.maillingAddress}
                    id="maillingAddress"
                    value={fields.maillingAddress ? fields.maillingAddress : ""}
                    onChange={handleChange}
                    className="custom-select"
                  ></Input>
                </FormGroup>
              </Col>
              { fields?.selectedMemberShip == 12 ||fields?.selectedMemberShip == 13  ?
                                           (
                                            <Col sm={6}>
                                                <FormGroup>
                                                <Label for="sector">Course <span className="text-danger">*</span></Label>
                                                      
                                                        <Creatable
                                                           components={{   Menu: Menu1 }}
                                                            isValidNewOption={isValidNewOption}
                                                            isMulti 
                                                            options={courseData}                             
                                                            value={sector}
                                                            onChange={handleSectorCourseChange}
                                                          />
                                                  </FormGroup>
                                                   <FormFeedback className="d-block">{errors.sector}</FormFeedback>
                                                  </Col>
            )  : ''  }
      { fields?.selectedMemberShip != 12 && fields?.selectedMemberShip != 13  ?
         (
              <Col sm={6}>
                <FormGroup>
                  <Label for="sector">Proposed Area of Sector <span className="text-danger">*</span></Label>
                  <Creatable
                    components={{ Menu }}
                    isValidNewOption={isValidNewOption}
                    isMulti
                    options={sectorsDrop.subcategory}
                    value={sector}
                    onChange={handleSectorChange}
                  />
                </FormGroup>
                <FormFeedback className="d-block">{errors.sector}</FormFeedback>
              </Col>
              )  : ''  }
              {/* {selectedMemberShip?.sub_category > 0 ? ( */}
              { fields?.selectedMemberShip != 12  && fields?.selectedMemberShip != 13  ? (
                <Col sm="6">
                  <FormGroup>
                    <Label for="sector">Proposed Area of Industry Report <span className="text-danger">*</span></Label>
                    <Creatable
                      components={{ Menu: projectMenu }}
                      isValidNewOption={isValidNewOption}
                      isMulti
                      options={subSubCategories}
                      value={project}
                      onChange={handleProjectChange}
                    />
                  </FormGroup>
                  <FormFeedback className="d-block">
                    {errors.project}
                  </FormFeedback>
                </Col>
               )  : ''  }  
              {/* ) : null} */}
              <Col sm={6}>
                <FormGroup>
                  <Label for="discount">Discount</Label>
                  <Input
                    invalid={!!errors.discount}
                    id="discount"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                      calculateDiscountAmount();
                    }}
                  />
                  <FormFeedback>
                    {!!errors.discount ? errors.discount[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Total Paid</Label>
                  <Input
                    id="total"
                    value={discountAmount}
                    disabled
                  />
                  <FormFeedback>
                    {!!errors.total ? errors.total[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="bussinessName">Business Name</Label>
                  <Input
                    invalid={!!errors.bussinessName}
                    id="bussinessName"
                    value={fields.bussinessName ? fields.bussinessName : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.bussinessName ? errors.bussinessName[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    invalid={!!errors.pincode}
                    id="pincode"
                    value={fields.pincode ? fields.pincode : ""}
                    onChange={((e) => (e.target.value.length <= 6 && !isNaN(e.target.value)) && handleChange(e))}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="gst">GST Number</Label>
                  <Input
                    invalid={!!errors.gst}
                    id="gst"
                    value={fields.gst ? fields.gst : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Payment Mode</Label>
                  <Input
                    type="select"
                    invalid={!!errors.mode}
                    id="mode"
                    value={fields.mode ? fields.mode : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              {fields.mode == 1 && (
                <Col sm="6">
                  <FormGroup>
                    <Label for="mode_type">Payment Mode Type</Label>
                    <Input
                      type="select"
                      invalid={!!errors.mode_type}
                      id="mode_type"
                      value={fields.mode_type ? fields.mode_type : ""}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      <option>select</option>
                      <option value="Web">Web</option>
                      <option value="App">App</option>
                      <option value="Intenet Banking">Intenet Banking</option>
                    </Input>
                    <FormFeedback>
                      {!!errors.mode_type ? errors.mode_type[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              )}
              {fields.mode == 0 && (
                <Col sm="6">
                  <FormGroup>
                    <Label for="mode_type">Payment Mode Type</Label>
                    <Input
                      type="select"
                      invalid={!!errors.mode_type}
                      id="mode_type"
                      value={fields.mode_type ? fields.mode_type : ""}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      <option>select</option>
                      <option value="Acc Transfer">Acc Transfer</option>
                      <option value="Bank Deposit">Bank Deposit</option>
                      <option value="Cheque Deposit">Cheque Deposit</option>
                    </Input>
                    <FormFeedback>
                      {!!errors.mode_type ? errors.mode_type[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              )}
              <Col sm="6">
                <FormGroup>
                  <Label for="paymentType">Payment Type</Label>
                  <Input
                    type="select"
                    invalid={!!errors.paymentType}
                    id="paymentType"
                    value={fields.paymentType ? fields.paymentType : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="neft">NEFT</option>
                    <option value="rtgs">RTGS</option>
                    <option value="credit card">Credit Card</option>
                    <option value="debit card">Debit Card</option>
                  </Input>

                  <FormFeedback>
                    {!!errors.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                                                <FormGroup>
                                                    <Label
                                                        >
                                                        Payment Date
                                                    </Label>
                                                    <Input
                                                        type="date"
                                                        id="payment_date"
                                                        className=""
                                                        value={fields?.payment_date ? fields.payment_date : ""}
                                                        
                                                        onChange={
                                                            handleChange
                                                        }
                                                       
                                                    />
                                                    <FormFeedback>
                                                       
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="trxId">Trx Id</Label>
                  <Input
                    invalid={!!errors.payment_id}
                    id="trxId"
                    value={fields.trxId ? fields.trxId : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.payment_id ? errors.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label for="formFileSm" class="form-label">
                    Payment Receipt{" "}
                  </label>
                  <input
                    class="form-control form-control-sm p-1"
                    id="formFileSm"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </FormGroup>
              </Col>

              <Col sm="10">
                <div className="text-center mb-3">
                  <Button color="secondary" onClick={() => setModal(false)}>
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                  // disabled={!_.isEmpty(user?.plan_detail)}
                  >
                    {/* {loading ? <Spinner /> : "Save"} */}
                    {loading ? <Spinner /> : "Save"}

                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddMembership;
