import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Redirect } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  projectreportCreate,
  changeReducerState,
} from "../../store/actions/ProjectReportActions";
import _ from "lodash";
import Creatable, { components } from "react-select";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
const addProjectReport = memo(({ leadDetail, setWsStatus, userId }) => {
  const [fields, setFields] = useState({
    name: leadDetail?.crm_user_tbl?.name,
    mobile: leadDetail?.crm_user_tbl?.mobile,
    email: leadDetail?.crm_user_tbl?.email,
    pincode: leadDetail?.pincode ?? "",
    leadId: leadDetail?.id ?? "",
  });
  const [project, setProject] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({});
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [projectreport, setProjectreport] = useState("");
  const [file, setFile] = useState("");
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);

  const {
    projectReports,
    createStatus,
    projectReportStatus,
    users: { user },
  } = useSelector((state) => state.projectReport);

  const { errors } = useSelector((state) => state.projectReport);

  console.log(errors, "errorserrors");
  useEffect(() => {
    const dataa = {
      lead_id: leadDetail?.id,
      activity_type: "Add Project Report",
      description: "Add Project Report Sale",
    };
    if (projectReportStatus) {
      dispatch(addActivity(dataa, token));
    }
  }, [projectReportStatus]);
  useMemo(() => projectReports, [projectReports]);

  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${3}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/subsectordata?industry=${e.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };

  const handleProjectReport = (e) => {
    setProjectreport(e);
    axios
      .get(`/api/crm/projectreport-list/${subCategory.value}/${e.value}`)
      .then((res) => {
        setProject(res?.data?.projectReport);
      })
      .catch((err) => console.log(err));
  };

  const selectedMemberShip = useMemo(() => {
    if (fields?.selectedMemberShip) {
      let selected = project?.find(
        (v) => v?.projectreports?.id == fields?.selectedMemberShip
      );
      return selected;
    }
  }, [fields?.selectedMemberShip]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
  };
  const validationForm = () => {
    let formIsValid = true;
    setError(error);
    return formIsValid;
  };
  console.log(fields?.selectedMemberShip ,'errorsss')
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      const formData = new FormData();

      formData.append("payment_img", file);
      formData.append("project_id", fields?.selectedMemberShip == undefined ? "" : fields?.selectedMemberShip);
      formData.append("user_id", userId ? userId : fields?.id);
      formData.append("name", fields?.name);
      formData.append("email", fields?.email);
      formData.append("mobile", fields?.mobile);
      formData.append("payment_id", fields?.payment_id == undefined ? "" : fields?.payment_id);
      formData.append("amount", selectedMemberShip?.projectreports?.amount == undefined ? "" : selectedMemberShip?.projectreports?.amount);
      formData.append(
        "associate_amount",
        selectedMemberShip?.projectreports?.associate_amount == undefined ? "" : selectedMemberShip?.projectreports?.associate_amount
      );
      formData.append(
        "project_name",
        selectedMemberShip?.projectreports?.project_name
      );
      formData.append("pincode", fields?.pincode);
      formData.append("paymentType", fields?.paymentType);
      formData.append("leadId", leadDetail?.id ?? "");
      formData.append("engaged", 1);
      formData.append("payment_mode", fields?.mode);
      formData.append("type", "project_report");

      dispatch(projectreportCreate(formData, token));
    }
  };

  useEffect(() => {
    setFields({
      ...fields,
      name: leadDetail?.full_name ?? "",
      mobile: leadDetail?.mobile ?? "",
      email: leadDetail?.email ?? "",
    });
  }, [leadDetail, modal]);

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 &&
    selectValue.length < parseInt(selectedMemberShip?.industries);
  useEffect(() => {
    if (createStatus) {
      setWsStatus((prev) => !prev);
      dispatch(changeReducerState());
      setModal(false);
      NotificationManager.success("Created Successfully", "Success");
    }
  }, [createStatus]);

  console.log(errors, "errors");

  if (!isAuthenticated) return <Redirect to="/login" />;

  console.log(leadDetail, "fields");

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Project Report
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add Project Report
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="sector">Sector</Label>

                  <Creatable
                    options={[...sectors]}
                    onChange={handleSector}
                    value={subCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">{errors.sector}</FormFeedback>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="industry">Industry</Label>
                  <Creatable
                    options={[...industry]}
                    onChange={(e) => {
                      setSubSubCategory(e);
                      handleProjectReport(e);
                    }}
                    // onChange={handleProjectReport}
                    value={subSubCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errors.industry}
                </FormFeedback>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="selectedMemberShip">Project Report <span className="text-danger pl-1">*</span></Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="selectedMemberShip"
                    invalid={!!errors?.project_id}
                    value={
                      fields?.selectedMemberShip
                        ? fields?.selectedMemberShip
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <option>select</option>
                    {_.size(project)
                      ? _.map(project, (mem) => (
                          <option
                            value={mem.projectreports?.id}
                            key={mem.projectreports?.id}
                          >
                            {mem.projectreports?.title}
                          </option>
                        ))
                      : null}
                  </Input>
                  <FormFeedback>
                    {!!errors?.selectedMemberShip
                      ? errors?.selectedMemberShip[0]
                      : ""}
                  </FormFeedback>
                  <FormFeedback>
                    {!!errors?.project_id
                      ? errors?.project_id[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="mobile">Mobile <span className="text-danger pl-1">*</span></Label>
                  <Input
                    invalid={!!errors.mobile}
                    id="mobile"
                    value={fields?.mobile ? fields?.mobile : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.mobile ? errors.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">Name <span className="text-danger pl-1">*</span></Label>
                  <Input
                    invalid={!!errors.name}
                    id="name"
                    value={fields.name ? fields.name : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.name ? errors.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">Email <span className="text-danger pl-1">*</span></Label>
                  <Input
                    invalid={!!errors.email}
                    id="email"
                    value={fields.email ? fields.email : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.email ? errors.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Amount <span className="text-danger pl-1">*</span></Label>
                  <Input
                    id="total"
                    invalid={!!errors?.amount}
                    value={selectedMemberShip?.projectreports?.amount}
                    disabled
                  />
                  <FormFeedback>
                    {!!errors.amount ? errors.amount[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Associate_amount</Label>
                  <Input
                    id="total"
                    invalid={!!errors?.associate_amount}
                    value={selectedMemberShip?.projectreports?.associate_amount}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    invalid={!!errors.pincode}
                    id="pincode"
                    value={fields.pincode ? fields.pincode : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Payment Mode</Label>
                  <Input
                    type="select"
                    invalid={!!errors.mode}
                    id="mode"
                    value={fields.mode ? fields.mode : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="paymentType">Payment Type</Label>
                  <Input
                    type="select"
                    invalid={!!errors.paymentType}
                    id="paymentType"
                    value={fields.paymentType ? fields.paymentType : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>

                  <FormFeedback>
                    {!!errors.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="payment_id">Payment Id <span className="text-danger pl-1">*</span></Label>
                  <Input
                    invalid={!!errors.payment_id}
                    id="payment_id"
                    value={fields.payment_id}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.payment_id ? errors.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
              <Col sm="10">
                <div className="text-center mb-3">
                  <Button
                    color="secondary"
                    onClick={() => setModal(false)}
                    className="mr-2"
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!_.isEmpty(user?.plan_detail)}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
});

export default addProjectReport;
