import React from "react";
import ConsultantDashboard from "./ConsultantDashboard";
import AssociateDashboard from "./AssociateDashboard";
import Dashboard from "./Dashboard";
import SalesDashboard from "./SalesDashboard";
import SuperAdmin from "./SuperAdmin";
import { useSelector } from "react-redux";
import InstituteDashboard from "./InstituteDashboard";
import DmtDashboard from "./DmtDashboard";
import PMFME from "../pmfme/Pmfme";
import ServiceDashboard from "./ServiceDashboard";
import FranchiseDashboard from "./FranchiseDashboard";
import TataPlayDashboard from "./TataPlayDashboard";
import MarketingDashboard from "./MarketingDashboard";
const ConditionalDashBoard = () => {
  const { campaigndetails, auth } = useSelector((state) => state.auth);


  if (auth?.campaign === "Content" || campaigndetails === "Production") {
    return <ConsultantDashboard />;
  }

  if (auth?.campaign === "Associate") {
    return <AssociateDashboard />;
  }
  if (auth?.campaign === "Franchise") {
    return <FranchiseDashboard />;
  }
  if (auth?.campaign === "Course College") {
    return <InstituteDashboard />;
  }
  if (auth?.campaign === "PMFME") {
    return <PMFME />;
  }
  if (auth?.campaign === "Social-Media") {
    return <DmtDashboard />;
  }
  if (auth?.campaign === "Service") {
    return <ServiceDashboard />;
  }
  if (auth?.campaign === "Franchise") {
    return <FranchiseDashboard />;
  }
  if (auth?.campaign === "Tata-Play") {
    return <TataPlayDashboard />;
  }

  if (auth?.campaign === "Marketing-Head") {
    return <MarketingDashboard />;
  }

  if( auth?.campaign==="Super-Admin" ){
    return <SuperAdmin/>
  }

  return <SalesDashboard />;
};

export default ConditionalDashBoard;
