import MainWrapper from "../MainWrapper";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { addCrmUser } from "../../store/actions/CrmUserActions";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import { FaSpinner } from "react-icons/fa";
import { getCampaign } from "../../store/actions/CampaignActions";
import _ from "lodash";

const Create = () => {
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [designations, setDesignations] = useState([]);

  const [designation, setDesignation] = useState("");
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState("");
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [vertical, setVertical] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [agentcode, setAgentCode] = useState("");
  const [isManager, setIsManager] = useState("");
  const [campaign, setCampaign] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    auth: { token },
  } = useSelector((state) => state.auth);
  useEffect(() => {
    axios
      .get(`/api/crm/departments/list`)
      .then((res) => setDepartments(res.data.categories))
      .catch((error) => console.log(error));

    axios
      .get(`/api/crm/roles?sort=asc`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => setRoles(res.data.roles.data))
      .catch((error) => console.log(error));
    axios
      .get(`/api/crm/get-manager`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => setManagers(res.data.manager))
      .catch((error) => console.log(error));
  }, [token]);
  useEffect(() => {
    dispatch(getCampaign());
  }, [token]);

  const { getcampaigndropdown } = useSelector((state) => state.campaignReducer);

  const handleDepartmentChange = (val) => {
    setDepartment(val);
    axios
      .get(`/api/crm/designation-list?department=${[val.value]}`)

      .then((res) => setDesignations(res.data.designations))
      .then((error) => console.log(error));
  };

  const handleDesignationChange = (value) => {
    setDesignation(value);
  };

  const handleManagerChange = (value) => {
    setManager(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("department", department.value);
    data.append("designation", designation.value);
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("password", password);
    data.append("password_confirmation", confirmpassword);
    data.append("address", address);
    data.append("dob", dob);
    data.append("gender", gender);
    data.append("agent_code", agentcode);
    data.append("role", role);
    data.append("campaign", campaign);
    data.append("isManager", isManager);
    data.append("vertical", vertical);

    data.append("parent_user", manager?.value);

    dispatch(addCrmUser(data, token));
  };

  const { crmadduserstatus, error, loading } = useSelector(
    (state) => state.crmUser
  );

  React.useMemo(() => {
    if (crmadduserstatus) {
      NotificationManager.success("Created Successfully", "Success");
      dispatch({ type: "RESET_ADD_USER_STATUS", payload: false });
      setName("");
      setEmail("");
      setMobile("");
      setDob("");

      history.push("/crm-user");
    }
  }, [crmadduserstatus]);

  return (
    <div>
      <MainWrapper>
        <Card>
          <CardHeader>Create User</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Name
                    </Label>
                    <Input
                      type="text"
                      value={name}
                      invalid={!!error.name}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setName(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.name ? error.name[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Email
                    </Label>
                    <Input
                      type="text"
                      value={email}
                      invalid={!!error.email}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setEmail(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.email ? error.email[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile
                    </Label>
                    <Input
                      type="text"
                      value={mobile}
                      invalid={!!error.mobile}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setMobile(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.mobile ? error.mobile[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="dob"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      D.O.B
                    </Label>
                    <Input
                      type="date"
                      value={dob}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setDob(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Vertical
                    </Label>
                    <Input
                      type="select"
                      value={vertical}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setVertical(value)}
                    >
                      <option value="">Select</option>
                      <option value="Membership">Membership</option>
                      <option value="Workshop">Workshop</option>
                      <option value="Project Report">Project Report</option>
                      <option value="Courses">Courses</option>
                      <option value="Franchise">Franchise</option>
                      <option value="Startup">Startup</option>
                      <option value="Edp">Edp</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Are you Manager
                    </Label>
                    <Input
                      type="select"
                      value={isManager}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setIsManager(value)}
                    >
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no"> No </option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="designation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Manager
                    </Label>
                    <Select
                      options={managers}
                      className="col-md-7 p-0"
                      onChange={handleManagerChange}
                      value={manager}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Gender
                    </Label>
                    <Input
                      type="select"
                      value={gender}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setGender(value)}
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female"> Female </option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="role"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Campaign
                    </Label>
                    <Input
                      type="select"
                      value={campaign}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setCampaign(value)}
                    >
                      <option value="">select</option>
                      {getcampaigndropdown
                        ? _.map(getcampaigndropdown?.activity, (ws, index) => (
                            <option value={ws.name} key={index}>
                              {ws.name}
                            </option>
                          ))
                        : null}
                    </Input>
                    <FormFeedback className="offset-5">
                      {!!error.role ? error.role[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="role"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Role
                    </Label>
                    <Input
                      type="select"
                      value={role}
                      invalid={!!error.role}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setRole(value)}
                    >
                      <option value="">select role</option>
                      {roles.map((role) => (
                        <option value={role.id} key={Math.random()}>
                          {role.name}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback className="offset-5">
                      {!!error.role ? error.role[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="password"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Password
                    </Label>
                    <Input
                      type="password"
                      value={password}
                      invalid={!!error.mobile}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.password ? error.password[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="password_confirmation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Confirm Password
                    </Label>
                    <Input
                      type="password"
                      value={confirmpassword}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setConfirmPassword(value)
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Address
                    </Label>
                    <Input
                      type="textarea"
                      value={address}
                      rows={4}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setAddress(value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="agentcode"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Agent Code
                    </Label>
                    <Input
                      type="text"
                      value={agentcode}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setAgentCode(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    {loading ? (
                      <FaSpinner />
                    ) : (
                      <Button
                        type="submit"
                        color="secondary"
                        size="sm"
                        className="mr-1"
                      >
                        Save
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner />
                    ) : (
                      <Button color="secondary" size="sm" className="mr-1">
                        Save & New
                      </Button>
                    )}
                    <a href="/crm-user">
                      <Button type="button" color="secondary" size="sm">
                        Close
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </MainWrapper>
    </div>
  );
};

export default Create;
