/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SmallLogo from "../assets/img/small-logo.png";
import { getallPermissions } from "../store/actions/AuthActions";
import { AiFillSetting, AiOutlineUserSwitch } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { TfiUser } from "react-icons/tfi";
import { HiOutlineUserCircle } from "react-icons/hi";
import { TbUserSearch } from "react-icons/tb";
import { PiTarget } from "react-icons/pi";
import { LuPieChart } from "react-icons/lu";
import {
  FaUser,
  FaUserCheck,
  FaUserTie,
  FaHandsHelping,
  FaSchool,
  FaList,
} from "react-icons/fa";
import Axios from "axios";
import { RiTeamFill } from "react-icons/ri";
import { GiTeamIdea } from "react-icons/gi";
import { useHistory } from "react-router-dom";
const Sidebar = ({ toggle }) => {
  const dispatch = useDispatch();
  const [leadCount, setLeadCount] = useState("");
  const {
    auth: { token },
    permissions: { permission },
  } = useSelector(({ auth }) => auth);

  const {
    location: { pathname },
  } = useHistory();

  const { campaigndetails, auth } = useSelector((state) => state.auth);

  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);

  useEffect(() => {
    Axios.get(`/api/crm/get-lead-used-count?role_id=${auth?.roles?.[0]?.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setLeadCount(res.data.leads))
      .catch((err) => console.log(err));
  }, [token]);

  return (
    <Fragment>
      <nav className={`sidebar sidebar-sticky ${toggle}`}>
        <div className="sidebar-content  js-simplebar">
          <Link className="sidebar-brand" to="/">
            <img src={"/iid-white-logo.png"} alt="IID" />
          </Link>
          <ul className="sidebar-nav">
            <li className="sidebar-item sidebar-item_1">
              <Link
                to="/"
                className={`font-weight-bold sidebar-link  ${pathname === "/" ? "link-active" : ""
                  }`}
              >
                <span className="logoicon">
                  <MdOutlineDashboard size={20} />
                </span>
                Dashboard
              </Link>
            </li>

            {/* {permissions.some(
              (item) => item?.name === "crm-leadreport-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/chart-report"
                    className={`font-weight-bold sidebar-link  ${pathname === "/chart-report" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <LuPieChart size={22} />
                    </span>
                    Sales Chart
                  </Link>
                </li>
              )} */}

            {permissions.some(
              (item) => item.module === "LEADS" && item?.name === "leads-view"
            ) ? (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/leads"
                  className={`font-weight-bold sidebar-link  ${pathname === "/leads" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <HiOutlineUserCircle size={15} />
                  </span>
                  <span className="text-warning">Leads ({leadCount})</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {/* {permissions.some((item) => item?.name === "agent-lead-list") ? (
              <li className="sidebar-item">
                <Link
                  to="/agent-leads"
                  className="font-weight-bold sidebar-link"
                >
                  Agent Leads
                </Link>
              </li>
            ) : null} */}

            {/* {permissions.some(
              (item) =>
                item.module === "CALL-CENTER-LEAD" &&
                item?.name === "call-center-view"
            ) ? (
              <>
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/lead"
                    className={`font-weight-bold sidebar-link  ${
                      pathname === "/lead" ? "link-active" : ""
                    }`}
                  >
                    <span>
                      <AiOutlineUserSwitch size={22} />
                    </span>
                    Lead
                  </Link>
                </li>
              </>
            ) : (
              ""
            )} */}

            {permissions.some(
              (item) =>
                item.module === "CALL-CENTER-LEAD" &&
                item?.name === "call-center-view"
            ) ? (
              <>
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/lead-search"
                    className={`font-weight-bold sidebar-link  ${pathname === "/lead-search" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <TbUserSearch size={22} />
                    </span>
                    Lead Search
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {permissions.some(
              (item) => item?.name === "crm-leadreport-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <a
                    href="#master"
                    data-toggle="collapse"
                    className="font-weight-bold sidebar-link collapsed"
                  >
                    <i className="align-middle" data-feather="sidebar" />{" "}
                    <span className="align-middle">
                      <span>
                        <AiFillSetting size={15} />
                      </span>
                      Lead Report
                    </span>
                  </a>
                  <ul
                    id="master"
                    className="sidebar-dropdown list-unstyled collapse "
                  >
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/task-report">
                        Task Report
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/lead-report">
                        Lead Report
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/agent-lead-report">
                        Agent Lead Report
                      </Link>
                    </li>

                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/agent-location-report">
                        Agent Location Report
                      </Link>
                    </li>

                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/agent-report">
                        Agent Wise Report
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/crm-source-report">
                        Crm Source Report
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/source-wise-report">
                        Source Wise
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

            {permissions.some((item) => item?.name === "user-target-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/users-target"
                  className={`font-weight-bold sidebar-link  ${pathname === "/users-target" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <PiTarget size={22} />
                  </span>
                  Users Target
                </Link>
              </li>
            )}

            {permissions.some((item) => item?.name === "user-target-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/vertical-target"
                  className={`font-weight-bold sidebar-link  ${pathname === "/vertical-target" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Vertical Target
                </Link>
              </li>
            )}

            {permissions.some((item) => item?.name === "consultant-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/consultant"
                  className={`font-weight-bold sidebar-link  ${pathname === "/consultant" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Brands
                </Link>
              </li>
            )}
            {permissions?.some(
              (item) => item.name === "frnachise-lead-status-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <a
                    href="#masterr"
                    data-toggle="collapse"
                    className="font-weight-bold sidebar-link collapsed"
                  >
                    <i className="align-middle" data-feather="sidebar" />{" "}
                    <span className="align-middle">
                      <span>
                        <AiFillSetting size={15} />
                      </span>
                      Brand Status
                    </span>
                  </a>
                  <ul
                    id="masterr"
                    className="sidebar-dropdown list-unstyled collapse "
                  >
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/brand-call-back-list">
                        Call Back Leads
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/brand-warm-back-list">
                        Warm Leads
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/brand-dead-back-list">
                        Dead Leads
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/brand-hot-back-list">
                        Hot Leads
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/brand-cold-back-list">
                        Cold Leads
                      </Link>
                    </li>
                    <li className="sidebar-item">
                      <Link className="sidebar-link" to="/brand-dump-list">
                        Dump Lead
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            {permissions.some((item) => item?.name === "startup-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/sales-consultant"
                  className={`font-weight-bold sidebar-link  ${pathname === "/sales-consultant" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Consultant
                </Link>
              </li>
            )}

            {permissions.some((item) => item?.name === "crm-shark-tank") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/cart-list"
                  className={`font-weight-bold sidebar-link  ${pathname === "/cart-list" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaList size={15} />
                  </span>
                  Cart List
                </Link>
              </li>
            )}

            {permissions.some((item) => item?.name === "hunar-lead-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/hunar-lead"
                  className={`font-weight-bold sidebar-link  ${pathname === "/hunar-lead" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Hunar Lead
                </Link>
              </li>
            )}

            {permissions.some((item) => item?.name === "pmfme-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/pmfme"
                  className={`font-weight-bold sidebar-link  ${pathname === "/pmfme" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  PMFME
                </Link>
              </li>
            )}
            {permissions.some(
              (item) => item?.name === "franchise-membership-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/franchise"
                    className={`font-weight-bold sidebar-link  ${pathname === "/franchise" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <FaHandsHelping size={15} />
                    </span>
                    Franchise Members
                  </Link>
                </li>
              )}
            {permissions.some((item) => item?.name === "crm-shark-tank") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/expo-lead"
                  className={`font-weight-bold sidebar-link  ${pathname === "/expo-lead" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Expo Lead
                </Link>
              </li>
            )}

            {permissions.some(
              (item) =>
                item.module === "SHARK_TANK" && item?.name === "crm-shark-tank"
            ) ? (
              <>
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/shark-tank-lead"
                    className={`font-weight-bold sidebar-link  ${pathname === "/shark-tank-lead" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <FaUserTie size={15} />
                    </span>
                    SharkTank Lead
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {permissions.some((item) => item?.name === "dmt-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/dmt-consultant"
                  className={`font-weight-bold sidebar-link  ${pathname === "/dmt-consultant" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Dmt Consultant
                </Link>
              </li>
            )}

            {permissions.some(
              (item) => item?.name === "free-workshop-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/free-workshop-list"
                    className={`font-weight-bold sidebar-link  ${pathname === "/free-workshop-list" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <FaHandsHelping size={15} />
                    </span>
                    Workshop Userlist
                  </Link>
                </li>
              )}

            {permissions.some(
              (item) => item?.name === "free-workshop-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/batch-list"
                    className={`font-weight-bold sidebar-link  ${pathname === "/batch-list" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <FaHandsHelping size={15} />
                    </span>
                    Waste Managment Batch
                  </Link>
                </li>
              )}

            {permissions.some(
              (item) => item?.name === "associate-team-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/associate-team"
                    className={`font-weight-bold sidebar-link  ${pathname === "/associate-team" ? "link-active" : ""
                      }`}
                  >
                    {" "}
                    <span>
                      <RiTeamFill />
                    </span>
                    Associate Team
                  </Link>
                </li>
              )}

            {permissions.some(
              (item) => item?.name === "associate-list-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/associate-list"
                    className={`font-weight-bold sidebar-link  ${pathname === "/associate-list" ? "link-active" : ""
                      }`}
                  >
                    {" "}
                    <span>
                      <RiTeamFill />
                    </span>
                    Associate List
                  </Link>
                </li>
              )}

            {permissions.some(
              (item) => item?.name === "associate-lead-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/associate-meeting-details"
                    className={`font-weight-bold sidebar-link  ${pathname === "/associate-meeting-details"
                      ? "link-active"
                      : ""
                      }`}
                  >
                    {" "}
                    <span>
                      <RiTeamFill />
                    </span>
                    Associate Leads
                  </Link>
                </li>
              )}

            {permissions.some(
              (item) => item?.name === "associate-note-lead-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/note-lead"
                    className={`font-weight-bold sidebar-link  ${pathname === "/note-lead" ? "link-active" : ""
                      }`}
                  >
                    {" "}
                    <span>
                      <RiTeamFill />
                    </span>
                    Associate Note Leads
                  </Link>
                </li>
              )}

            {permissions.some(
              (item) => item?.name === "associate-location-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/associate-location"
                    className={`font-weight-bold sidebar-link  ${pathname === "/associate-location" ? "link-active" : ""
                      }`}
                  >
                    {" "}
                    <span>
                      <RiTeamFill />
                    </span>
                    Associate Location List
                  </Link>
                </li>
              )}

            {/* {permissions.some(
              (item) => item?.name === "franchise-team-view"
            ) && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/franchise-list"
                  className={`font-weight-bold sidebar-link  ${
                    pathname === "/franchise-list" ? "link-active" : ""
                  }`}
                >
                  <span>
                    <GiTeamIdea size={15} />
                  </span>
                  Brand Onboarding
                </Link>
              </li>
            )} */}

            {permissions.some(
              (item) => item?.name === "franchise-team-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/franchise-list-investor"
                    className={`font-weight-bold sidebar-link  ${pathname === "/franchise-list-investor" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <GiTeamIdea size={15} />
                    </span>
                    Franchise Investor
                  </Link>
                </li>
              )}
            {permissions?.some((item) => item.name === "frnachise-lead-status-view") &&
              <li className="sidebar-item sidebar-item_1">
                <a
                  href="#master"
                  data-toggle="collapse"
                  className="font-weight-bold sidebar-link collapsed"
                >
                  <i className="align-middle" data-feather="sidebar" />{" "}
                  <span className="align-middle">
                    <span>
                      <AiFillSetting size={15} />
                    </span>
                    Lead Status
                  </span>
                </a>
                <ul
                  id="master"
                  className="sidebar-dropdown list-unstyled collapse "
                >
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/call-back-list">
                      Call Back Leads
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/warm-call-list">
                      Warm Leads
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/dead-call-list">
                      Dead Leads
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/hot-call-list">
                      Hot Leads
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/cold-call-list">
                      Cold Leads
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/franchise-dump-data">
                      Dump Lead
                    </Link>
                  </li>



                </ul>
              </li>
            }
            {permissions.some(
              (item) => item?.name === "tata-play-lead-view"
            ) && (
                <li className="sidebar-item sidebar-item_1">
                  <Link
                    to="/tata-play-list"
                    className={`font-weight-bold sidebar-link  ${pathname === "/tata-play-list" ? "link-active" : ""
                      }`}
                  >
                    <span>
                      <GiTeamIdea size={15} />
                    </span>
                    Tata Play Lead
                  </Link>
                </li>
              )}

            {permissions.some((item) => item?.name === "institute-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/institute-team"
                  className={`font-weight-bold sidebar-link  ${pathname === "/institute-team" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaSchool size={15} />
                  </span>
                  Institution Team
                </Link>
              </li>
            )}

            {permissions.some((item) => item?.name === "master-data-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/membership-report"
                  className={`font-weight-bold sidebar-link  ${pathname === "/membership-report" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaSchool size={15} />
                  </span>
                  Master Data
                </Link>
              </li>
            )}

            {/* <li className="sidebar-item">
              <Link
                to="/engaged-agents"
                className="font-weight-bold sidebar-link"
              >
                Engaged Agent Leads
              </Link>
            </li> */}

            {permissions.some(
              (item) =>
                item.module === "CRM_USER_LIST" &&
                item?.name === "	crm-user-view"
            ) ? (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/users"
                  className={`font-weight-bold sidebar-link  ${pathname === "/users" ? "link-active" : ""
                    }`}
                >
                  <i className="align-middle" data-feather="sidebar" />{" "}
                  <span className="align-middle">Converted Users</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {permissions?.some((item) => item.module === "services-view") ? (
              <li className="sidebar-item sidebar-item_1">
                <a
                  href="#masterreport"
                  data-toggle="collapse"
                  className="font-weight-bold sidebar-link collapsed"
                >
                  <i className="align-middle" data-feather="sidebar" />{" "}
                  <span className="align-middle">
                    <span>
                      <AiFillSetting size={15} />
                    </span>
                    Reports
                  </span>
                </a>
                <ul
                  id="masterreport"
                  className="sidebar-dropdown list-unstyled collapse "
                >
                  {/* <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/workshop-payment-report"
                    >
                      Workshop Report
                    </Link>
                  </li>

                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/payment-list">
                      Payment List
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/edp-report-list">
                      Edp Report
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/customize-payment-report"
                    >
                      Customize Payment Report
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/course-payment-report">
                      Course Payment Report
                    </Link>
                  </li> */}

                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/membership-report">
                      Master Data
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/service-payment">
                      Service Payment List
                    </Link>
                  </li>

                  <li className="sidebar-item">
                    <Link
                      to="/service-enquiry"
                      className={`font-weight-bold sidebar-link  ${pathname === "/service-enquiry" ? "link-active" : ""
                        }`}
                    >
                      Service Enquiry
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}

            {permissions?.some((item) => item.module === "services-view") ? (
              <li className="sidebar-item sidebar-item_1">
                <a
                  href="#servicerequest"
                  data-toggle="collapse"
                  className="font-weight-bold sidebar-link collapsed"
                >
                  <i className="align-middle" data-feather="sidebar" />{" "}
                  <span className="align-middle">
                    <span>
                      <AiFillSetting size={15} />
                    </span>
                    Membership Service Requests
                  </span>
                </a>
                <ul
                  id="servicerequest"
                  className="sidebar-dropdown list-unstyled collapse "
                >
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/service-gst-requests">
                      Gst Service Requests
                    </Link>
                  </li>
                  {/* <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/service-startup-requests"
                    >
                      Startup Requests
                    </Link>
                  </li> */}
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/service-udyam-requests">
                      Udyam Requests
                    </Link>
                  </li>
                  {/* <li className="sidebar-item">
                    <Link className="sidebar-link" to="/service-ipr-requests">
                      FSSAI Requests
                    </Link>
                  </li> */}
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/service-bankable-requests"
                    >
                      Bankable Requests
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}

            {permissions?.some((item) => item.module === "services-view") ? (
              <li className="sidebar-item sidebar-item_1">
                <a
                  href="#paidservicerequest"
                  data-toggle="collapse"
                  className="font-weight-bold sidebar-link collapsed"
                >
                  <i className="align-middle" data-feather="sidebar" />{" "}
                  <span className="align-middle">
                    <span>
                      <AiFillSetting size={15} />
                    </span>
                    Paid Service Requests
                  </span>
                </a>
                <ul
                  id="paidservicerequest"
                  className="sidebar-dropdown list-unstyled collapse "
                >
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/paid-service-gst-requests"
                    >
                      Gst Service Requests
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/paid-service-startup-requests"
                    >
                      Startup Requests
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/paid-service-udyam-requests"
                    >
                      Udyam Requests
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/paid-service-ipr-requests"
                    >
                      FSSAI Requests
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/paid-service-bankable-requests"
                    >
                      Bankable Requests
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* /source-list */}
            {permissions.some((item) => item?.name === "source-view") && (
              <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/source-list"
                  className={`font-weight-bold sidebar-link  ${pathname === "/source-list" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Source List
                </Link>
              </li>
            )}
             {permissions.some((item) => item?.name === "disposition-view") && (
             <li className="sidebar-item sidebar-item_1">
                <Link
                  to="/disposition-list"
                  className={`font-weight-bold sidebar-link  ${pathname === "/disposition-list" ? "link-active" : ""
                    }`}
                >
                  <span>
                    <FaHandsHelping size={15} />
                  </span>
                  Disposition List
                </Link>
              </li>
                )}

            {permissions?.some((item) => item.module === "CRM-SETTINGS") ? (
              <li className="sidebar-item sidebar-item_1">
                <a
                  href="#masterr"
                  data-toggle="collapse"
                  className="font-weight-bold sidebar-link collapsed"
                >
                  <i className="align-middle" data-feather="sidebar" />{" "}
                  <span className="align-middle">
                    <span>
                      <AiFillSetting size={15} />
                    </span>
                    Settings
                  </span>
                </a>
                <ul
                  id="masterr"
                  className="sidebar-dropdown list-unstyled collapse "
                >
                  <li className="sidebar-item">
                    <Link className="sidebar-link" to="/crm-user">
                      Crm Users
                    </Link>
                  </li>
                  {permissions?.some(
                    (item) => item.name === "permissions-view"
                  ) && (
                      <li className="sidebar-item">
                        <Link className="sidebar-link" to="/campaign">
                          Campaign
                        </Link>
                      </li>
                    )}

                  {permissions?.some(
                    (item) => item.name === "permissions-view"
                  ) && (
                      <li className="sidebar-item">
                        <Link className="sidebar-link" to="/prioities">
                          Priority
                        </Link>
                      </li>
                    )}
                  {permissions?.some(
                    (item) => item.name === "permissions-view"
                  ) && (
                      <li className="sidebar-item">
                        <Link className="sidebar-link" to="/roles">
                          Roles
                        </Link>
                      </li>
                    )}
                  {permissions?.some(
                    (item) => item.name === "permissions-view"
                  ) && (
                      <li className="sidebar-item">
                        <Link className="sidebar-link" to="/permissions">
                          Permissions
                        </Link>
                      </li>
                    )}
                  {permissions?.some(
                    (item) => item.name === "assign-permission-view"
                  ) && (
                      <li className="sidebar-item">
                        <Link className="sidebar-link" to="/asign-permissions">
                          Assign Permission
                        </Link>
                      </li>
                    )}
                </ul>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

export default Sidebar;
