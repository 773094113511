import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import axios from "axios";
import {
  Container,
  InputGroup,
  Input,
  CustomInput,
  ButtonGroup,
  Modal,
  ModalBody,
  FormGroup,
  FormFeedback,
  Button,
  ModalHeader,
} from "reactstrap";
import { FiEye } from "react-icons/fi";
import TataPlayNote from "../../consultant/sub-component/Tata-Play-Note";
import { CSVLink } from "react-csv";
import { Fragment } from "react";
import Loader from "../../layouts/Loader";
import { useSelector } from "react-redux";
import { FiTrash, FiEdit, FiChevronDown } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";
const BrandConsultant = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [paginate, setPaginate] = useState(20);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [consultantId, setConsultantId] = useState("");
  const [state, setState] = useState("");
  const [createdBy, seCreatedBy] = useState([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);

  const [createdByAdmin, seCreatedByAdmin] = useState([]);
  const [user, setUser] = useState([]);
  const [supplierType, setSupplierType] = useState("");
  const [professionalType, setProfessionalType] = useState([]);
  const [instituteType, setInstituteType] = useState("");
  const [appType, setAppType] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [details, setDetails] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [mailShared, setMailShared] = useState("");
  const [industry, setIndustry] = useState([]);
  const [id, setId] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [professionalservices, setProfessionalServices] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [associateType, setAssociateType] = useState("");
  const [dmttype, setDmtType] = useState("");
  const [modal, setModal] = useState(false);
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [newAdd, setNewAdd] = useState(false);
  const [fields, setFields] = useState("");
  const [errors, setErrors] = useState({});
  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  const permissions = React.useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/get-consultant-list-brand-dispostion?type=dead&sort=${sort}&search=${search}&page=${page}&paginate=${paginate}&start=${startDate}&end=${endDate}&supplierType=${supplierType}&sector=${
          subCategory.value || ""
        }&industry=${subSubCategory.value || ""}&associateType=${
          associateType || ""
        }&instituteType=${instituteType || ""}&professionalType=${
          professionalType.value || ""
        }&created_by=${consultantId}&dmttype=${dmttype}&eventdate=${eventDate}&mail_shared=${mailShared}&disposition_type=${dispositiontype}&disposition=${disposition}&state=${state}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        // setContent(res.data.consultantList?.data);
        // seCreatedBy(res.data.consultantList?.child);
        setContent(res.data?.consultantList?.data || []);
        setFrom(res.data.consultantList?.from);
        setTo(res.data.consultantList?.to);
        setTotal(res.data.consultantList?.total);
        setPerPage(res.data.consultantList?.per_page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err?.response?.data?.message);
      });
  }, [
    sort,
    page,
    refresh,
    paginate,
    search,
    startDate,
    endDate,
    consultantId,
    supplierType,
    subCategory,
    subSubCategory,
    associateType,
    instituteType,
    professionalType,
    auth,
    createdBy,
    dmttype,
    eventDate,
    disposition,
    dispositiontype,
    mailShared,
    state,
  ]);
  //

  useEffect(() => {
    if (associateType == 2 || associateType == 3 || associateType == 4) {
      axios(`/api/crm/sectordata`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then((res) => setSectors(res.data?.subcategory))
        .catch((err) => console.log(err));
    }
  }, [associateType]);

  useEffect(() => {
    const url = new URL(window.location).searchParams;
    const mo = url.get("leaddate");
    const associate = url.get("associate");
    const source = url.get("leadsource");
    const dis = url.get("dispositiontype");
    const disp = url.get("disposition");
    const mail = url.get("mailshared");
    console.log(source, "Src");
    if (mo) {
      setStartDate(mo);
      setEndDate(mo);
    }
    if (associate) {
      setAssociateType(associate);
    }

    if (dis) {
      setDispositionType(dis);
    }
    if (disp) {
      setDisposition(disp);
    }
    if (mail) {
      setMailShared(mail);
    }
  }, []);

  useEffect(() => {
    if (subCategory) {
      axios(`/api/crm/subsectordatanew?industry=${subCategory?.value}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then((res) =>
          setIndustry(
            Array.isArray(res.data?.subSubCats) ? res.data?.subSubCats : []
          )
        )
        .catch((err) => console.log(err));
    }
  }, [subCategory]);

  useEffect(() => {
    axios
      .get(`/api/crm/get-manager`, {
        headers: { authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => seCreatedBy(res.data?.manager))
      .catch((error) => console.log(error));
  }, [auth]);

  useEffect(() => {
    axios
      .get(`/api/crm/get-manager-admin`, {
        headers: { authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => seCreatedByAdmin(res.data?.manager))
      .catch((error) => console.log(error));
  }, [auth]);

  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post("/consultant/destroy", {
          ids: JSON.stringify(deleteIds),
        })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post("/consultant/destroy", {
          ids: JSON.stringify([id]),
        })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        content.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };
  const dropdownOpenToggle = () =>
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleApproved = (e, result) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      const data = new FormData();
      data.append("id", result.id);
      data.append("status", result.consultant_approve ? 0 : 1);
      axios
        .post("/api/crm/approve-consultant", data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleDetails = (e, is) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setDetails(is);
  };
  const handleSector = (sub) => {
    console.log(sub);
    setSubCategory(sub);
    axios
      .get(`/api/crm/subsectordata?industry=${sub?.value}`)
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };
  const handleConsultantType = (type) => {
    let typeArray = [];
    let option = [
      { value: 1, label: "Professional Expert" },
      { value: 2, label: "Supplier" },
      { value: 3, label: "Industry Expert" },
      { value: 4, label: "Enterpreneur" },
      { value: 5, label: "Institutional" },
      { value: 6, label: "Exhibitors" },
      { value: 7, label: "Visitors" },
      { value: 8, label: "Franchise Network" },
      { value: 9, label: "Startup Network" },
      { value: 10, label: "Others" },
      { value: 11, label: "Tata Play" },
      { value: 12, label: "Lecturer" },
      { value: 13, label: "Brand Onboarding" },
    ];

    type.split(",").map((op) => {
      let ld = option.find((m) => {
        if (m.value == op) {
          return m;
        }
      });

      if (!_.isEmpty(ld)) {
        typeArray.push(ld.label);
      }
    });

    return typeArray.join(",");
  };
  const handleSubIndustry = (data) => {
    let result = [];

    data.map((sub, i) => {
      sub.subindustrydata.map((subSub, i) => {
        if (!_.isEmpty(subSub.sub_industry)) {
          result.push(subSub.sub_industry.name);
        }
      });
    });
    return result.join(",");
  };

  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };

  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("sample", fields);
    axios
      .post("/api/crm/consultant-import", data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        window.location.reload();
        NotificationManager.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setLoading(false);
          setErrors(err.response.data.errors);
          NotificationManager.error(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.errors);
        }
      });
  };

  const csvHead = [
    [
      "Name",
      "Associate Type",
      "Email",
      "Source",
      "Event Name",
      "Sector",
      "Industry",
      "Mobile",
      "Contact Person",
      "Website",
      "Pin Code",
      "State",
      "Created By",
      auth?.campaign === "Tata-Play" ? "Is Mail Shared" : null,
      auth?.campaign === "Tata-Play" ? "Remarks" : null,
      "Created Date",
    ],
  ];

  const csvData = content.map((cv) => [
    cv.name,
    cv.type ? handleConsultantType(cv.type) : null,
    cv?.email,
    cv.source,
    cv?.event_name,
    cv.type != 5
      ? cv.industry
          .map((ind, i) => _.get(ind, "industrydata.name", ""))
          .join(",")
      : null,

    cv.type != 5 ? handleSubIndustry(cv.industry) : null,
    cv.mobile,
    cv.contact_person,
    cv.website_url,
    cv.pincode,
    cv.state,
    cv?.referred_by == "dmt"
      ? cv?.referred_by
      : cv?.agent_id
      ? cv.created_by_crm_user?.name
      : cv?.created_by_crm_user?.name,
    auth?.campaign === "Tata-Play" ? cv?.mail_shared : null,
    auth?.campaign === "Tata-Play" ? cv.remarks : null,
    cv.created_at ? moment(cv.created_at).format("DD/MM/YYYY") : null,
  ]);
  const exportData = csvHead.concat(csvData);

  const newLocal = associateType == 5;
  return (
    <MainWrapper>
      <main className="content">
        <div className="page-header mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="c-card-top d-flex flex-wrap">
                <div className="c-card-top-title">
                  <h4 className="mb-0">Consultant</h4>
                </div>
                <div className="c-card-top-inputs c-card-top-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                    <span className="custom-select">Search</span>

                    <Input
                      placeholder="Search here..."
                      onChange={handleSearch}
                      className="c-form-control-serch"
                    />
                    <div class="input-group-append">
                      <button
                        title="Advance Search"
                        onClick={() => {
                          advanceSearch
                            ? setAdvanceSearch(false)
                            : setAdvanceSearch(true);
                        }}
                        id="c_searchBlock_toggle"
                        class="btn btn-secondary btn-sm c-card-top-block-icon"
                        type="button"
                      >
                        <FiChevronDown />
                      </button>
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        id="button-addon2"
                      >
                        GO
                      </button>
                    </div>
                  </div>

                  <div
                    className={advanceSearch ? "c-card-top-block" : "d-none"}
                    id="c_searchBlock"
                  >
                    <div className="c-card-top-block-sec">
                      <div className="c-card-top-block-item">
                        <label>Associate Type</label>
                        <select
                          id="paginate"
                          onChange={(e) => setAssociateType(e.target.value)}
                          value={associateType}
                          className="custom-select"
                        >
                          <option value="" selected>
                            Associate Type
                          </option>
                          <option value="1">Professional Expert</option>
                          <option value="2">Supplier</option>
                          <option value="3">Industry Expert</option>
                          <option value="4">Enterpreneur</option>
                          <option value="5">Institutional</option>
                          <option value="6">Exhibitors</option>
                          <option value="7">Visitors</option>
                          <option value="8">Franchise Network</option>
                          <option value="9">Startup Network</option>
                          <option value="10">Others</option>
                          <option value="11">Tata Play</option>
                          <option value="12">Lecturer</option>
                          <option value="13">Brand Onboarding</option>
                        </select>
                      </div>

                      {associateType == 1 ? (
                        <div className="c-card-top-block-item">
                          <label>Service Category</label>

                          <Select
                            options={[...professionalservices]}
                            onChange={(e) => setProfessionalType(e)}
                            value={professionalType}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        </div>
                      ) : null}
                      {associateType == 2 ? (
                        <div className="c-card-top-block-item">
                          <label>Supplier Type</label>
                          <select
                            id="paginate"
                            onChange={(e) => setSupplierType(e.target.value)}
                            value={supplierType}
                            className="custom-select"
                          >
                            <option value="" selected>
                              Supplier Type
                            </option>
                            <option value="1">Platinum</option>
                            <option value="2">Gold</option>
                            <option value="3">Associate</option>
                            <option value="4">General</option>
                          </select>
                        </div>
                      ) : null}

                      <div className="c-card-top-block-item">
                        <label>Dmt Type</label>
                        <select
                          id="paginate"
                          onChange={(e) => setDmtType(e.target.value)}
                          value={dmttype}
                          className="custom-select"
                        >
                          <option value="" selected>
                            Select
                          </option>
                          <option value="dmt">Dmt</option>
                        </select>
                      </div>

                      {associateType == 2 ||
                      associateType == 3 ||
                      associateType == 4 ? (
                        <>
                          <div className="c-card-top-block-item">
                            <label>Sector</label>

                            <Select
                              options={[...sectors]}
                              onChange={handleSector}
                              value={subCategory}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Industry</label>

                            <Select
                              options={[...industry]}
                              onChange={(e) => setSubSubCategory(e)}
                              value={subSubCategory}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </>
                      ) : null}
                      {associateType == 5 ? (
                        <div className="c-card-top-block-item">
                          <label>Institute Type</label>
                          <select
                            id="paginate"
                            onChange={(e) => setInstituteType(e.target.value)}
                            value={instituteType}
                            className="custom-select"
                          >
                            <option value="" selected>
                              Institute Type
                            </option>
                            <option value="University">University</option>
                            <option value="Engineering College">
                              Engineering College
                            </option>
                            <option value="Medical College">
                              Medical College
                            </option>
                            <option value="Research Institute">
                              Research Institute
                            </option>
                            <option value="Agriculture">Agriculture</option>
                            <option value="College">College</option>
                            <option value="Sector Skill Council">
                              Sector Skill Council
                            </option>
                            <option value="Association">Association</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      ) : null}
                      <div className="c-card-top-block-item">
                        <label>App Type</label>
                        <select
                          id="paginate"
                          onChange={(e) => setAppType(e.target.value)}
                          value={appType}
                          className="custom-select"
                        >
                          <option value="">App Type </option>
                          <option value="ADMIN"> ADMIN</option>
                          <option value="CRM">CRM</option>
                        </select>
                      </div>
                      {appType == "ADMIN" ? (
                        <div className="c-card-top-block-item">
                          <label>Created By Admin</label>
                          <select
                            id="paginate"
                            onChange={(e) => setConsultantId(e.target.value)}
                            value={consultantId}
                            className="custom-select"
                          >
                            <option value="">Created By Admin</option>
                            {createdByAdmin
                              ? createdByAdmin.map((cr) => (
                                  <option value={cr?.value} key={cr.value}>
                                    {cr?.label}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      ) : (
                        <div className="c-card-top-block-item">
                          <label>Created By CRM</label>
                          <select
                            id="paginate"
                            onChange={(e) => setConsultantId(e.target.value)}
                            value={consultantId}
                            className="custom-select"
                          >
                            <option value="">Created By Crm</option>
                            {createdBy
                              ? createdBy.map((cr) => (
                                  <option value={cr?.value} key={cr.value}>
                                    {cr?.label}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      )}

                      <div className="c-card-top-block-item">
                        <label>Disposition Type</label>
                        <select
                          id="paginate"
                          onChange={(e) => setDispositionType(e.target.value)}
                          value={dispositiontype}
                          className="custom-select"
                        >
                          <option value=""> Select </option>

                          <option value="Invalid_Number">Invalid Number</option>
                          <option value="Not_Interested">Not Interested</option>
                          <option value="Budget Issue">Budget Issue</option>
                        </select>
                      </div>

                      <div className="c-card-top-block-item">
                        <label>Mail Shared</label>
                        <select
                          id="paginate"
                          onChange={(e) => setMailShared(e.target.value)}
                          value={mailShared}
                          className="custom-select"
                        >
                          <option value=""> Select </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No </option>
                        </select>
                      </div>

                      <div className="c-card-top-block-item">
                        <label>Start Date</label>

                        <Input
                          type="date"
                          placeholder="Start Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={startDate}
                        />
                      </div>
                      <div className="c-card-top-block-item">
                        <label>End Date</label>

                        <Input
                          type="date"
                          placeholder="End Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                        />
                      </div>

                      <div className="c-card-top-block-item">
                        <label>Event Date</label>

                        <Input
                          type="date"
                          placeholder="Event Date"
                          onChange={(e) => setEventDate(e.target.value)}
                          value={eventDate}
                        />
                      </div>
                      <div className="c-card-top-block-item">
                        <label>State</label>
                        <select
                          id="paginate11"
                          onChange={(e) => setState(e.target.value)}
                          value={state}
                          className="custom-select"
                        >
                          <option value="">State</option>
                          <option value="New Delhi">New Delhi</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Tamilnadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                        </select>
                      </div>
                      <div className="c-card-top-block-action">
                        <button
                          onClick={() => setAdvanceSearch(false)}
                          className="btn btn-sm btn-secondary mr-2"
                        >
                          Apply
                        </button>

                        <button
                          onClick={() => setAdvanceSearch(false)}
                          className="btn btn-sm btn-secondary mr-2"
                        >
                          Cancel
                        </button>
                        {associateType ? (
                          <button
                            className="btn btn-sm btn-secondary "
                            title="Refresh"
                          >
                            <i className="fa fa-refresh" />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card-top-buttons">
                  <ButtonGroup className="">
                    {permissions?.some(
                      (item) => item?.name === "consultant-create"
                    ) && (
                      <Link to="/consultant-create" className="">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm mr-2"
                        >
                          <i className="fa fa-plus btn-i-icon"></i> Add New
                        </button>
                      </Link>
                    )}
                  </ButtonGroup>
                  <ButtonGroup className="">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm ml-1"
                      onClick={() => setModal(true)}
                    >
                      <i className="fa fa-plus btn-i-icon"></i> Import Data
                    </button>
                  </ButtonGroup>
                  <Modal isOpen={modal} toggle={() => setModal(false)}>
                    <ModalHeader toggle={() => setModal(false)}>
                      Import Consultants
                      <a
                        href="https://iid-bucket-s3.s3.ap-south-1.amazonaws.com/sample.xlsx"
                        style={{ "margin-left": "170px", color: "blue" }}
                      >
                        Sample Sheet
                      </a>
                      {/* <a
                        href="https://admin.iid.org.in/public/storage/consultantAssociateType.xlsx"
                        style={{ "margin-left": "170px", color: "blue" }}
                      >
                        Associate Sheet
                      </a> */}
                    </ModalHeader>
                    <ModalBody>
                      <form onSubmit={handleSubmit}>
                        <FormGroup>
                          <CustomInput
                            type="file"
                            id="file"
                            name="file"
                            label="Choose File"
                            onChange={handleChange}
                            invalid={!!errors?.file}
                            required
                          />
                          <FormFeedback>
                            {!!errors.file ? errors.file[0] : ""}
                          </FormFeedback>
                        </FormGroup>
                        <div className="text-center mb-3">
                          <Loader loading={loading}>
                            <Button
                              type="submit"
                              color="secondary"
                              size="sm"
                              className="mr-1"
                            >
                              Save
                            </Button>
                            <Button
                              type="button"
                              color="secondary"
                              size="sm"
                              onClick={handleClose}
                            >
                              Close
                            </Button>
                          </Loader>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header d-card-header d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        className="btn btn-secondary btn-sm mr-1"
                        disabled={!deleteIds.length}
                        onClick={handleDelete}
                      >
                        <i className="fa fa-trash pr-1" /> Delete
                      </button>

                      <button
                        className="btn btn-secondary btn-sm btn-circle"
                        title="Refresh"
                        onClick={() => setRefresh((refresh) => refresh + 1)}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                    <div>
                      <select
                        id="sort"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                        className="custome-select small-select mr-1"
                        // style={{ width: 135 }}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>

                      <CSVLink
                        filename={"consultant.csv"}
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To CSV"
                        data={exportData}
                      >
                        <i className="fa fa-file-excel-o" />
                      </CSVLink>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    onChange={handleMultiSelect}
                                    checked={
                                      deleteIds.length === content.length &&
                                      deleteIds.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">
                                  Associate Type
                                </th>
                                {associateType == 5 ? (
                                  <>
                                    {" "}
                                    <th className="font-weight-bold">
                                      Institute Type
                                    </th>
                                  </>
                                ) : null}

                                <th className="font-weight-bold">Email</th>

                                <th className="font-weight-bold">Source</th>
                                <th className="font-weight-bold">Event Name</th>
                                {associateType != 5 ? (
                                  <>
                                    {associateType == 1 ? (
                                      <th className="font-weight-bold">
                                        Service Category
                                      </th>
                                    ) : (
                                      <th className="font-weight-bold">
                                        Sector
                                      </th>
                                    )}
                                  </>
                                ) : null}
                                {associateType != 5 && associateType != 1 ? (
                                  <>
                                    <th className="font-weight-bold">
                                      Industry
                                    </th>
                                  </>
                                ) : null}

                                <th className="font-weight-bold">
                                  Contact Number
                                </th>

                                <th className="font-weight-bold">
                                  Contact Person
                                </th>

                                {/* <th className="font-weight-bold">
                                  Disposition Type
                                </th> */}

                                <th className="font-weight-bold">
                                  Disposition
                                </th>

                                <th className="font-weight-bold">Website</th>
                                <th className="font-weight-bold">Pincode</th>
                                <th className="font-weight-bold">Remarks</th>
                                <th className="font-weight-bold">Created By</th>
                                <th className="font-weight-bold">
                                  Created Date
                                </th>
                                {(Array.isArray(permission)
                                  ? permission
                                  : []
                                )?.some(
                                  (item) =>
                                    item?.name === "crm-consultant-approve-view"
                                ) && (
                                  <th className="font-weight-bold">Approved</th>
                                )}

                                <th className="font-weight-bold text-right">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              <Fragment>
                                {loading ? (
                                  <Fragment>
                                    <tr>
                                      <td colSpan={7}>
                                        <Loader loading={loading} />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {content.length ? (
                                      <Fragment>
                                        {_.map(content, (is, i) => (
                                          <tr key={is.id}>
                                            <td>
                                              <CustomInput
                                                id={`default-${is.id}`}
                                                type="checkbox"
                                                checked={deleteIds.some(
                                                  (d) => d === is.id
                                                )}
                                                onChange={(e) =>
                                                  handleSingleSelect(e, is.id)
                                                }
                                              />
                                            </td>
                                            <td>
                                              <span
                                                style={{
                                                  color: "#3647c3",
                                                }}
                                              >
                                                <a
                                                  href={`/consultant-lead/${is?.id}`}
                                                >
                                                  {is.type == "13"
                                                    ? is?.bussinessName
                                                    : is?.name}
                                                </a>
                                              </span>
                                            </td>
                                            <td>
                                              {is.type
                                                ? handleConsultantType(is.type)
                                                : null}
                                            </td>
                                            {newLocal ? (
                                              <td>{is.institute_type}</td>
                                            ) : null}

                                            <td>{is?.email ?? ""}</td>

                                            <td>{is.source}</td>
                                            <td>{is.event_name}</td>
                                            {associateType != 5 ? (
                                              <>
                                                <td>
                                                  <span
                                                    className="dot-tooltip"
                                                    title={is.industry
                                                      .map((ind, i) =>
                                                        _.get(
                                                          ind,
                                                          "industrydata.name",
                                                          ""
                                                        )
                                                      )
                                                      .join(",")}
                                                  >
                                                    {is.industry
                                                      .map((ind, i) =>
                                                        _.get(
                                                          ind,
                                                          "industrydata.name",
                                                          ""
                                                        )
                                                      )
                                                      .join(",")}
                                                  </span>
                                                </td>
                                              </>
                                            ) : null}
                                            {associateType != 1 &&
                                            associateType != 5 ? (
                                              <>
                                                <td>
                                                  <span
                                                    className="dot-tooltip"
                                                    title={handleSubIndustry(
                                                      is.industry
                                                    )}
                                                  >
                                                    {handleSubIndustry(
                                                      is.industry
                                                    )}
                                                  </span>
                                                </td>
                                              </>
                                            ) : null}
                                            <td>{is?.mobile}</td>
                                            <td>{is.contact_person}</td>
                                            {/* <td>{is?.disposition_type}</td> */}
                                            <td>{is?.disposition}</td>
                                            <td>
                                              {is.website_url ? (
                                                <a
                                                  href={
                                                    !is?.website_url?.startsWith(
                                                      "https"
                                                    )
                                                      ? `https://${is?.website_url}`
                                                      : is?.website_url
                                                  }
                                                  target="_blank"
                                                >
                                                  view
                                                </a>
                                              ) : null}
                                            </td>

                                            <td>
                                              {is.pincode ? is.pincode : null}
                                            </td>
                                            <td>{is.remarks}</td>
                                            <td>
                                              <strong>
                                                {is?.referred_by == "dmt"
                                                  ? is?.referred_by
                                                  : is?.agent_id
                                                  ? is.created_by_crm_user?.name
                                                  : is?.created_by_crm_user
                                                      ?.name}
                                              </strong>
                                            </td>
                                            <td>
                                              <strong>
                                                {moment(is.created_at).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </strong>
                                            </td>
                                            {permission?.some(
                                              (item) =>
                                                item.name ===
                                                "crm-consultant-approve-view"
                                            ) && (
                                              <td>
                                                <a
                                                  href="#"
                                                  onClick={(e) =>
                                                    handleApproved(e, is)
                                                  }
                                                  className={
                                                    is.consultant_approve
                                                      ? `badge badge-success text-white`
                                                      : `badge badge-danger text-white`
                                                  }
                                                >
                                                  {is.consultant_approve
                                                    ? "Active"
                                                    : "Approve"}
                                                </a>
                                              </td>
                                            )}

                                            <td className="text-right">
                                              <a
                                                className="c-icon-btn-light mr-2"
                                                href="#"
                                                onClick={(e) =>
                                                  handleDetails(e, is)
                                                }
                                              >
                                                <FaList />
                                              </a>
                                              {permission?.some(
                                                (item) =>
                                                  item?.name ===
                                                  "consultant-edit"
                                              ) && (
                                                <Link
                                                  className="c-icon-btn-light mr-2"
                                                  to={`/consultant-edit/${is?.id}`}
                                                >
                                                  <FiEdit />
                                                </Link>
                                              )}
                                              {is?.type == 11 ? (
                                                <a
                                                  href="#"
                                                  className="text-danger pl-3"
                                                  onClick={() => {
                                                    setOpenNotesModal(true);
                                                    setId(is?.id);
                                                  }}
                                                >
                                                  <FiEye />
                                                </a>
                                              ) : null}
                                            </td>
                                          </tr>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan={7}>No data found</td>
                                      </tr>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <select
                          id="paginate"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                          className="custom-select custom-select-page"
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                        {/* <select className="custom-select custom-select-page">
                                                    <option selected>10</option>
                                                    <option value="1">
                                                        20
                                                    </option>
                                                    <option value="2">
                                                        30
                                                    </option>
                                                    <option value="3">
                                                        40
                                                    </option>
                                                </select> */}
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={perPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        isOpen={openNotesModal}
        toggle={() => setOpenNotesModal(!openNotesModal)}
      >
        <ModalHeader toggle={() => setIsOpen(!openNotesModal)}>
          Notes
        </ModalHeader>
        <ModalBody className="p-0 m-0">
          <TataPlayNote id={id} />
        </ModalBody>
      </Modal>

      <Modal isOpen={isOpen} size="sm">
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          User Contact Details
        </ModalHeader>

        <ModalBody className="industry-overflow">
          {console.log(details, "detils")}
          <div className="row">
            <div className="col-md-12">
              <p>
                <strong>Mobile: </strong> {details.mobile}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <strong>Password: </strong> {details.plain_password}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <strong>Email: </strong> {details.email}
              </p>{" "}
            </div>
            <div className="col-md-12">
              <p>
                <strong>Address: </strong> {details.address}
              </p>{" "}
            </div>
            {details?.mou && (
              <div className="col-md-12">
                <label>Mou</label>
                <a
                  href={`https://iid-bucket-s3.s3.ap-south-1.amazonaws.com/${details?.mou}`}
                  target="_blank"
                >
                  <FaRegFilePdf size={40} color="red" />
                </a>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </MainWrapper>
  );
};

export default BrandConsultant;
