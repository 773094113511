import MainWrapper from "../MainWrapper";
import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import axios from "axios";
import Select, { components } from "react-select";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleCrmUser,
  updateCrmUser,
} from "../../store/actions/CrmUserActions";
import { NotificationManager } from "react-notifications";
import { getCampaign } from "../../store/actions/CampaignActions";
import _ from "lodash";

const Edit = () => {
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [designations, setDesignations] = useState([]);
  const [designation, setDesignation] = useState("");
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState("");
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [agentcode, setAgentCode] = useState("");
  const [isManager, setIsManager] = useState("");
  const [campaign, setCampaign] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    auth: { token },
  } = useSelector((state) => state.auth);

  const { crmsingleuser, crmupdateuserstatus, error, message } = useSelector(
    (state) => state.crmUser
  );

  const history = useHistory();

  useEffect(() => {
    axios
      .get(`/api/crm/departments/list`)
      .then((res) => setDepartments(res.data.categories))
      .catch((error) => console.log(error));
    axios
      .get(`/api/crm/roles?sort=asc`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => setRoles(res.data.roles.data))
      .catch((error) => console.log(error));
    axios
      .get(`/api/crm/get-manager`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => setManagers(res.data.manager))
      .catch((error) => console.log(error));
  }, [token]);

  const handleDepartmentChange = (val) => {
    setDepartment(val);
    axios
      .get(`/api/crm/designation-list?department=${[val.value]}`)

      .then((res) => setDesignations(res.data.designations))
      .then((error) => console.log(error));
  };

  useEffect(() => {
    dispatch(getCampaign());
  }, [token]);

  const { getcampaigndropdown } = useSelector((state) => state.campaignReducer);

  const handleDesignationChange = (value) => {
    setDesignation(value);
  };

  const handleManagerChange = (value) => {
    setManager(value);
  };

  useEffect(() => {
    dispatch(getSingleCrmUser(id, token));
  }, [id, token]);

  useEffect(() => {
    console.log(crmsingleuser);
  }, [crmsingleuser]);

  useEffect(() => {
    if (crmsingleuser) {
      setName(crmsingleuser?.user?.name);
      setEmail(crmsingleuser?.user?.email);
      setMobile(crmsingleuser?.user?.mobile);
      setDob(crmsingleuser?.user?.details?.dob);
      setDepartment(crmsingleuser?.user?.departments);
      setDesignation(crmsingleuser?.user?.designations);
      setGender(
        crmsingleuser?.user?.details?.gender
          ? crmsingleuser?.user?.details?.gender
          : null
      );
      setAddress(crmsingleuser?.user?.details?.address);
      setPassword(crmsingleuser?.user?.plain_password);
      setConfirmPassword(crmsingleuser?.user?.plain_password);
      setAgentCode(crmsingleuser?.user?.agent_code);
      setRole(crmsingleuser?.user?.roles[0]?.id);
      setManager({
        value: crmsingleuser?.user?.reporting_user?.id,
        label: crmsingleuser?.user?.reporting_user?.name,
      });
      setIsManager(crmsingleuser?.user?.isManager);
      setCampaign(crmsingleuser?.user?.campaign);
    }
  }, [crmsingleuser]);

  console.log(crmsingleuser);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("id", id);
    data.append("department", department?.value);
    data.append("designation", designation?.value);
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("password", password);
    data.append("address", address);
    data.append("dob", dob);
    data.append("gender", gender);
    data.append("agentcode", agentcode);
    data.append("role", role);
    data.append("parent_user", manager.value);
    data.append("campaign", campaign);
    data.append("isManager", isManager);
    dispatch(updateCrmUser(data, token));
  };

  React.useMemo(() => {
    if (crmupdateuserstatus) {
      NotificationManager.success("Updated Successfully", "Success");
      history.push("/crm-user");
    }
  }, [crmupdateuserstatus, error]);

  return (
    <div>
      <MainWrapper>
        <Card>
          <CardHeader>Edit User</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Name
                    </Label>
                    <Input
                      type="text"
                      value={name}
                      invalid={!!error.name}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setName(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.name ? error.name[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Email
                    </Label>
                    <Input
                      type="text"
                      value={email}
                      invalid={!!error.email}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setEmail(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.email ? error.email[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile
                    </Label>
                    <Input
                      type="text"
                      value={mobile}
                      invalid={!!error.mobile}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setMobile(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.mobile ? error.mobile[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="dob"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      D.O.B
                    </Label>
                    <Input
                      type="date"
                      value={dob}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setDob(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="department"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Department
                    </Label>
                    <Select
                      options={departments}
                      className="col-md-7 p-0"
                      onChange={handleDepartmentChange}
                      value={department}
                    />
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="designation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Designation
                    </Label>
                    <Select
                      options={designations}
                      className="col-md-7 p-0"
                      onChange={handleDesignationChange}
                      value={designation}
                    />
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Are you Manager
                    </Label>
                    <Input
                      type="select"
                      value={isManager}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setIsManager(value)}
                    >
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no"> No </option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="designation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Manager
                    </Label>
                    <Select
                      options={managers}
                      className="col-md-7 p-0"
                      onChange={handleManagerChange}
                      value={manager}
                    />
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Gender
                    </Label>
                    <Input
                      type="select"
                      value={gender}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setGender(value)}
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female"> Female </option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="role"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Campaign
                    </Label>
                    <Input
                      type="select"
                      value={campaign}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setCampaign(value)}
                    >
                      <option value="">select</option>
                      {getcampaigndropdown
                        ? _.map(getcampaigndropdown?.activity, (ws, index) => (
                            <option value={ws.name} key={index}>
                              {ws.name}
                            </option>
                          ))
                        : null}
                    </Input>
                    <FormFeedback className="offset-5">
                      {!!error.role ? error.role[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="role"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Role
                    </Label>
                    <Input
                      type="select"
                      value={role}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setRole(value)}
                    >
                      <option value="">select role</option>
                      {roles.map((role) => (
                        <option value={role.id} key={Math.random()}>
                          {role.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="password"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Password
                    </Label>
                    <Input
                      type="password"
                      value={password}
                      invalid={!!error.password}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                    <FormFeedback className="offset-5">
                      {!!error.password ? error.password[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="password_confirmation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Confirm Password
                    </Label>
                    <Input
                      type="password"
                      value={confirmpassword}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setConfirmPassword(value)
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Address
                    </Label>
                    <Input
                      type="textarea"
                      value={address}
                      rows={4}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setAddress(value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5}>
                  <FormGroup className="row">
                    <Label
                      for="agentcode"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Agent Code
                    </Label>
                    <Input
                      type="text"
                      value={agentcode}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setAgentCode(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    <Button
                      type="submit"
                      color="secondary"
                      size="sm"
                      className="mr-1 p-2"
                    >
                      Save
                    </Button>
                    <Button color="secondary" size="sm" className="mr-1 p-2">
                      Save & New
                    </Button>
                    <a href="/crm-user">
                      <Button
                        type="button"
                        color="secondary"
                        size="sm"
                        className="p-2"
                      >
                        Close
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </MainWrapper>
    </div>
  );
};

export default Edit;
