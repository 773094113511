import React, { Fragment,useState,useEffect } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import MainWrapper from "./components/MainWrapper";
import Login from "./components/auth/Login";
import PasswordChange from "./components/auth/PasswordChange";
import Leads from "./components/lead/List";
import CallLeadList from "./components/lead/CallLeadList";
import CartList from "./components/cart-list/index";
import EngagedAgents from "./components/lead/EngagedAgents";
import FranchiseList from "./components/franchise-team/FranchiseList";
import LeadDetail from "./components/pages/LeadDetail";
import ImportLead from "./components/pages/ImportLead";
import NewLead from "./components/lead/Create";
import EditLead from "./components/lead/Edit";
import LeadRating from "./components/pages/LeadRating";
import LeadStatus from "./components/pages/LeadStatus";
import LeadSource from "./components/pages/LeadSource";
import List from "./components/users/role/List";
import Permission from "./components/users/permissions/List";
import AssignPermission from "./components/users/permissions/AssignPermission";
import Priority from "./components/priority/Create";
import Priorities from "./components/priority/Index";
import PriorityEdit from "./components/priority/Edit";
import CreateCampaign from "./components/campaign/Create";
import Campaign from "./components/campaign/Index";
import EditCampaign from "./components/campaign/Edit";
import CreateTask from "./components/lead/Task";
import BookWorkShopList from "./components/book-workshop/List";
import BookWorkShopCreate from "./components/book-workshop/Create";
import Consultant from "./consultant/consultant";
import ConsultantCreate from "./consultant/create";
import PmfmeCreate from "./pmfme/create";
import ConsultantEdit from "./consultant/edit";
import BookWorkShopEdit from "./components/book-workshop/Edit";
import MemberShipList from "./components/book-membership/Index";
import MemberShipCreate from "./components/book-membership/Create";
import MemberShipEdit from "./components/book-membership/Edit";
import Users from "./components/users/user-list/Index";
import LeadPage from "./components/lead/LeadPage";
import AssociateList from "./components/associate/associatelist";
import LeadDetailsPage from "./components/lead/Details";
import UserDetailsPage from "./components/users/Details";
import AddCrmUser from "./components/crm-user/Create";
import crmUser from "./components/crm-user/Index";
import crmUserEdit from "./components/crm-user/Edit";
import AgentLead from "./components/lead/agentlead";
import AddFranchise from "./components/franchise-team/Create";
import AddMeeting from "./components/meeting-details/create";
import FreeWorkshopList from "./components/freeworkshop/FreeWorkshopList";
import AssociateTeam from "./components/meeting-details/List";
import FranchiseEdit from "./components/franchise-team/Edit";
import FranchiseDetail from "./components/franchise-team/Detail";
import ConsultantDetails from "./consultant/Details";
import EditMeeting from "./components/meeting-details/Edit";
import AddInstitute from "./components/institution-team/create";
import InstituteTeam from "./components/institution-team/List";
import EditInstitute from "./components/institution-team/Edit";
import AssociateDetail from "./components/meeting-details/Detail";
import InsituteDetail from "./components/institution-team/Details";
import ConsultantDashboard from "./components/ConsultantDashboard";
import ConsultantEditDetailed from "./consultant/EditDetailed";
import PmfmeEditDetailed from "./pmfme/edit";
import ConditionalDashBoard from "./components/ConditionalDashBoard";
import HeaderNavBar from "./layouts/HeaderNavBar";
import Sidebar from "./layouts/Sidebar";
import SharkTank from "./components/ad-campaign/sharktank";
import Pmfme from "./pmfme/Pmfme";
import PmfmeDetails from "./pmfme/detail";
import SupplierPartnerLead from "./components/supplier-partnerlead/Index";
import WorkshopPaymentReport from "./components/report/WorkshoPaymentReport";
import PaymentList from "./components/report/PaymentList";
import EdpPurchaseList from "./components/report/EdpPurchaseList";
import ExpoList from "./components/expo-list/expo-list";
import CoursePaymentReport from "./components/report/CoursePaymentReport";
import CreateHunarIndia from "./components/hunar-india-lead/create";
import CustomizePaymentReport from "./components/report/CustomizePaymentReport";
import HunarLead from "./components/hunar-india-lead";
import UpdateHunarIndia from "./components/hunar-india-lead/edit";
import MembershipReport from "./components/report/MembershipReport";
import DmtConsultant from "./consultant/dmt-consultant";
import BatchDataList from "./components/batch/batchDataList";
import ServiceGstRequest from "./components/service-requests/ServiceGstRequest";
import StartUpRequest from "./components/service-requests/StartUpReport";
import UdyamRequest from "./components/service-requests/UdyamRequest";
import ServiceIprRequest from "./components/service-requests/ServiceIprRequest";
import ServiceCustomizeRequest from "./components/service-requests/ServiceCustomizeRequest";
import SalesConsultant from "./consultant/sales-consultant";
import PaidServiceGstRequest from "./components/paid-service-requests/ServiceGstRequest";
import PaidStartUpRequest from "./components/paid-service-requests/StartUpReport";
import PaidUdyamRequest from "./components/paid-service-requests/UdyamRequest";
import PaidServiceIprRequest from "./components/paid-service-requests/ServiceIprRequest";
import PaidServiceCustomizeRequest from "./components/paid-service-requests/ServiceCustomizeRequest";
import ServicesEnquiry from "./components/services/ServicesEnquiry";
import AssociateMeetingDetails from "./components/associate/meetingdetails";
import ServicePaymentList from "./components/report/ServicePaymentList";
import CreateFranchiseInvestor from "./components/franchise-team/create-franchise-investor";
import FranchiseInvestorList from "./components/franchise-team/list-franchise-investor";
import FranchiseInvestorEdit from "./components/franchise-team/edit-franchise-investor";
import NotesLead from "./components/meeting-details/notesLead";
import TataPlayLeadCreate from "./components/tata-play-lead/Create";
import TataPlayLeadList from "./components/tata-play-lead/List";
import EditTataPlayLead from "./components/tata-play-lead/Edit";
import AddAssociateLocation from "./components/associate-location/create";
import AssociateLocation from "./components/associate-location/List";
import EditAssociateLocation from "./components/associate-location/Edit";
import Franchise from "./franchise/franchise";
import LeadSearch from "./components/lead/LeadSearch";
import UserTarget from "./components/pages/UserTarget/UserTargetList";
import AddUserTarget from "./components/pages/UserTarget/AddUserTarget";
import SourceList from "./components/pages/Source/SourceList";
import DispositionList from "./components/pages/Dispositiion/DispositionList";
import ChartReport from "./components/pages/ChartReport";
import CrmSourceReport from "./components/lead-report/crm-source-report";
import AgentReport from "./components/lead-report/agent-report";
import SourceWiseReport from "./components/lead-report/source-wise-report";
import LeadReport from "./components/lead-report/lead-report";
import VerticalTarget from "./components/pages/Vertical/VerticalTarget";
import AgentLeadReport from "./components/lead-report/agent-lead-report";
// import CampaignWiseReport from "./components/lead-report/campaign-wise-report";
import FranchiseInvestorDetail from "./franchise/FranchiseDetail";
import Task from "./components/task-report";
import CallBackList from "./components/franchise-team/callBackList";
import WarmCallList from "./components/franchise-team/warmCallList";
import HotCallList from "./components/franchise-team/hotCallList";
import ColdCallList from "./components/franchise-team/coldCallList";
import DeadCallList from "./components/franchise-team/deadCallList";
import AssociateNotesLead from "./components/associate/associatenoteslead";
import DumpData from "./components/franchise-team/dumpData";
import CrashPage from "./components/pages/CrashPage";
import AgentLocationReport from "./components/agent-location-report/List";
import BrandCallBack from "./components/brand/brandCallBack";
import BrandWarmBack from "./components/brand/brandWarm";
import BrandHotBack from "./components/brand/brandHotList";
import BrandColdBack from "./components/brand/brandColdList";
import BrandDumpBack from "./components/brand/brandDupList";
import BrandDeadBack from "./components/brand/brandDead";
import PaymentStatus from "./components/payments/PaymentStatus";
import PaymentDetail from "./components/payments/PaymentDetails";
const Routes = () => {
  const [manager, setManager] = useState([]);
  const {
    auth,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  useEffect(() => {
    Axios.get(`/api/crm/crm-managers`, {
      headers: { Authorization: `Bearer ${auth}` },
    })
      .then((res) => {
        setManager(res?.data)
      }).catch((err) => err)
  }, [auth]);
  console.log(manager,"manager");
  console.log(auth,"auth");
  const data=manager?.users
?.  some((item)=>item?.value===auth?.id);
  console.log(data,"data");
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/password-change" exact component={PasswordChange} />
          <MainWrapper nested={true}>
            {/* <Route path="/" exact component={CrashPage} /> */}
            <Route path="/" exact component={data?ConditionalDashBoard:CrashPage} />
            <Route path="/leads" exact component={Leads} />
            <Route path="/leads-payments" exact component={PaymentStatus} />
            <Route path="/payment-detail/:id" exact component={PaymentDetail} />
            <Route path="/dead-call-list" exact component={DeadCallList} />
            <Route path="/franchise-dump-data" exact component={DumpData} />
            <Route
              path="/associate-notes-lead"
              exact
              component={AssociateNotesLead}
            />
            <Route path="/call-back-list" exact component={CallBackList} />
            <Route
              path="/brand-call-back-list"
              exact
              component={BrandCallBack}
            />
            <Route
              path="/brand-warm-back-list"
              exact
              component={BrandWarmBack}
            />
            <Route
              path="/brand-dead-back-list"
              exact
              component={BrandDeadBack}
            />
            <Route path="/brand-hot-back-list" exact component={BrandHotBack} />
            <Route
              path="/brand-cold-back-list"
              exact
              component={BrandColdBack}
            />
            <Route
              path="/brand-dump-back-list"
              exact
              component={BrandDumpBack}
            />
            <Route path="/cold-call-list" exact component={ColdCallList} />
            <Route path="/warm-call-list" exact component={WarmCallList} />
            <Route path="/hot-call-list" exact component={HotCallList} />
            <Route path="/franchise" exact component={Franchise} />
            <Route path="/cart-list" exact component={CartList} />
            <Route path="/task-report" exact component={Task} />
            <Route path="/engaged-agents" exact component={EngagedAgents} />
            <Route path="/lead-details" exact component={LeadDetail} />
            <Route path="/leads/imports" exact component={ImportLead} />
            <Route path="/leads/add" exact component={NewLead} />
            <Route path="/leads/edit/:id" exact component={EditLead} />
            <Route path="/leads/rating" exact component={LeadRating} />
            <Route path="/leads/status" exact component={LeadStatus} />
            <Route path="/leads/source" exact component={LeadSource} />
            <Route path="/roles" exact component={List} />
            <Route path="/permissions" exact component={Permission} />
            <Route path="/pmfme" exact component={Pmfme} />
            <Route path="/call-lead-list" exact component={CallLeadList} />
            <Route
              path="/crm-source-report"
              exact
              component={CrmSourceReport}
            />
            <Route
              path="/source-wise-report"
              exact
              component={SourceWiseReport}
            />
            {/* <Route path="/campaign-wise-report" exact component={CampaignWiseReport} /> */}
            <Route
              path="/asign-permissions"
              exact
              component={AssignPermission}
            />
            <Route path="/add-prioity" exact component={Priority} />
            <Route path="/prioities" exact component={Priorities} />
            <Route path="/prioities/edit/:id" exact component={PriorityEdit} />
            <Route path="/add-campaign" exact component={CreateCampaign} />
            <Route path="/Campaign" exact component={Campaign} />
            <Route path="/Campaign/edit/:id" exact component={EditCampaign} />
            <Route path="/add-task" exact component={CreateTask} />
            <Route
              path="/booking-workshop"
              exact
              component={BookWorkShopList}
            />
            <Route
              path="/booking-workshop/create"
              exact
              component={BookWorkShopCreate}
            />
            <Route
              path="/booking-workshop/edit/:id"
              exact
              component={BookWorkShopEdit}
            />
            <Route path="/membership" exact component={MemberShipList} />
            <Route
              path="/membership/create"
              exact
              component={MemberShipCreate}
            />
            <Route
              path="/membership/edit/:id"
              exact
              component={MemberShipEdit}
            />
            <Route path="/users" exact component={Users} />
            <Route path="/user-profile/:id" exact component={UserDetailsPage} />
            <Route path="/lead" exact component={LeadPage} />
            <Route path="/lead/:id/details" exact component={LeadDetailsPage} />
            <Route path="/add-crm-user" exact component={AddCrmUser} />
            <Route path="/crm-user" exact component={crmUser} />
            <Route path="/crm-user/edit/:id" exact component={crmUserEdit} />
            <Route path="/agent-leads" exact component={AgentLead} />
            <Route path="/consultant" exact component={Consultant} />
            <Route path="/dmt-consultant" exact component={DmtConsultant} />
            <Route
              path="/consultant-create"
              exact
              component={ConsultantCreate}
            />
            <Route path="/pmfme-create" exact component={PmfmeCreate} />
            <Route
              path="/consultant-edit/:id"
              exact
              component={ConsultantEdit}
            />
            <Route path="/pmfme-edit/:id" exact component={PmfmeEditDetailed} />
            <Route path="/franchise-list" exact component={FranchiseList} />
            <Route path="/add-franchise" exact component={AddFranchise} />
            <Route path="/add-associate" exact component={AddMeeting} />
            <Route path="/associate-team" exact component={AssociateTeam} />
            <Route path="/associate/edit/:id" exact component={EditMeeting} />
            <Route path="/franchise/edit/:id" exact component={FranchiseEdit} />
            <Route path="/add-institute" exact component={AddInstitute} />
            <Route path="/institute-team" exact component={InstituteTeam} />
            <Route path="/institute/edit/:id" exact component={EditInstitute} />

            <Route
              path="/franchise-detail/:id"
              exact
              component={FranchiseDetail}
            />

            <Route
              path="/associate-detail/:id"
              exact
              component={AssociateDetail}
            />
            <Route
              path="/institution-detail/:id"
              exact
              component={InsituteDetail}
            />

            <Route
              path="/consultant-lead/:id"
              exact
              component={ConsultantDetails}
            />

            <Route path="/pmfme-lead/:id" exact component={PmfmeDetails} />

            <Route
              path="/consultant-edit-detailed/:id"
              exact
              component={ConsultantEditDetailed}
            />
            <Route path="/shark-tank-lead" exact component={SharkTank} />

            <Route
              path="/supplier-partner-leads"
              exact
              component={SupplierPartnerLead}
            />

            {/* <Route
              path="/workshop-payment-report"
              exact
              component={WorkshopPaymentReport}
            /> */}

            {/* {/ <Route path="/payment-list" exact component={PaymentList} /> /} */}

            {/* <Route path="/edp-report-list" exact component={EdpPurchaseList} />
            <Route
              path="/course-payment-report"
              exact
              component={CoursePaymentReport}
            />

            <Route
              path="/customize-payment-report"
              exact
              component={CustomizePaymentReport}
            />*/}
            <Route
              path="/membership-report"
              exact
              component={MembershipReport}
            />
            <Route
              path="/service-payment"
              exact
              component={ServicePaymentList}
            />
            <Route path="/expo-lead" exact component={ExpoList} />

            <Route
              path="/create-hunar-lead"
              exact
              component={CreateHunarIndia}
            />
            <Route path="/hunar-lead" exact component={HunarLead} />
            <Route path="/hunar-edit/:id" exact component={UpdateHunarIndia} />
            <Route
              path="/free-workshop-list"
              exact
              component={FreeWorkshopList}
            />
            <Route path="/batch-list" exact component={BatchDataList} />

            <Route
              path="/service-gst-requests"
              exact
              component={ServiceGstRequest}
            />
            <Route
              path="/service-startup-requests"
              exact
              component={StartUpRequest}
            />
            <Route
              path="/service-udyam-requests"
              exact
              component={UdyamRequest}
            />
            <Route
              path="/service-ipr-requests"
              exact
              component={ServiceIprRequest}
            />
            <Route
              path="/service-bankable-requests"
              exact
              component={ServiceCustomizeRequest}
            />
            <Route path="/sales-consultant" exact component={SalesConsultant} />
            <Route
              path="/paid-service-gst-requests"
              exact
              component={PaidServiceGstRequest}
            />
            <Route
              path="/paid-service-startup-requests"
              exact
              component={PaidStartUpRequest}
            />
            <Route
              path="/paid-service-udyam-requests"
              exact
              component={PaidUdyamRequest}
            />
            <Route
              path="/paid-service-ipr-requests"
              exact
              component={PaidServiceIprRequest}
            />
            <Route
              path="/paid-service-bankable-requests"
              exact
              component={PaidServiceCustomizeRequest}
            />
            <Route path="/service-enquiry" exact component={ServicesEnquiry} />
            <Route path="/associate-list" exact component={AssociateList} />
            <Route
              path="/associate-meeting-details"
              exact
              component={AssociateMeetingDetails}
            />
            <Route
              path="/create-franchise-investor"
              exact
              component={CreateFranchiseInvestor}
            />
            <Route
              path="/franchise-list-investor"
              exact
              component={FranchiseInvestorList}
            />
            <Route
              path="/franchise-edit-investor/edit/:id"
              exact
              component={FranchiseInvestorEdit}
            />
            <Route path="/note-lead" exact component={NotesLead} />

            <Route
              path="/tata-play-create"
              exact
              component={TataPlayLeadCreate}
            />
            <Route path="/tata-play-list" exact component={TataPlayLeadList} />

            <Route
              path="/tata-play-edit/edit/:id"
              exact
              component={EditTataPlayLead}
            />

            <Route
              path="/add-associate-location"
              exact
              component={AddAssociateLocation}
            />
            <Route
              path="/associate-location"
              exact
              component={AssociateLocation}
            />

            <Route
              path="/associate-location/edit/:id"
              exact
              component={EditAssociateLocation}
            />

            <Route path="/users-target" exact component={UserTarget} />
            <Route path="/add-user-target" exact component={AddUserTarget} />

            <Route path="/lead-search" exact component={LeadSearch} />
            <Route path="/source-list" exact component={SourceList} />
            <Route path="/disposition-list" exact component={DispositionList} />
            <Route path="/chart-report" exact component={ChartReport} />
            <Route path="/agent-report" exact component={AgentReport} />
            <Route path="/vertical-target" exact component={VerticalTarget} />
            <Route path="/lead-report" exact component={LeadReport} />
            <Route
              path="/agent-lead-report"
              exact
              component={AgentLeadReport}
            />
            <Route
              path="/franchise-investor-detail/:id"
              exact
              component={FranchiseInvestorDetail}
            />
            <Route
              path="/agent-location-report"
              exact
              component={AgentLocationReport}
            />
          </MainWrapper>
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routes;
